import { call, put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../../axios/axiosInstance";
import { notify } from "../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import apiEndpoints from "../../../axios/api";
import { VIEW_TESTIMONIAL_DATA, VIEW_TESTIMONIAL_DATA_FALIURE, VIEW_TESTIMONIAL_DATA_SUCCESS } from "../../constants";

export const getTestimonialReq = async({data})=>{   

    return axiosInstance.get(`${apiEndpoints.GET_TESTIMONIAL_API}?user_program_id=${data.userProgramId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getTestimonial({data}){
    try{
        let result = yield call(getTestimonialReq, {data})
        if(result.statusCode === 200){
            yield put({ type: VIEW_TESTIMONIAL_DATA_SUCCESS, payload: result.data});  
        }else{
            yield put({ type: VIEW_TESTIMONIAL_DATA_FALIURE });
        }
    }catch(error){
        yield put({ type: VIEW_TESTIMONIAL_DATA_FALIURE, message: error.message });
        console.log(error)

    }
}


function* TestimonialSaga(){
    yield takeLatest(VIEW_TESTIMONIAL_DATA, getTestimonial)
}

export default TestimonialSaga;