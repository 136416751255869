import * as type from '../../constants';

const initialState = {
  prelimAssessment:[],
  loading: false,
  updateLoading: false,
  downloadLoader: false,
  error: null
}

export function prelimAssessmentReducer(state = initialState, action) {
  switch (action.type) {
    //get
    case type.GET_PRE_ASSESSMENT:
      return {
        ...state,
        loading: true,
      }
    case type.GET_PRE_ASSESSMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        prelimAssessment: {
          prelim_assesment_data: typeof action.payload?.prelim_assesment_data === 'object' && !Array.isArray(action.payload?.prelim_assesment_data) ?  {
            ...action.payload.prelim_assesment_data
          } : []
        } 
      }
    case type.GET_PRE_ASSESSMENT_DATA_FALIURE:
        return {
          ...state,
          prelimAssessment: null,
          loading: false,
          error: action.message
        }
    

    //delete
    case type.DEL_PRE_ASSESSMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        prelimAssessment: {...state.prelimAssessment, prelim_assesment_data: []}
      }

    case type.DEL_PRE_ASSESSMENT_DATA_FALIURE:
        return {
          ...state,
          loading: false,
          error: action.message
        }
    
    //add
    case type.ADD_PRE_ASSESSMENT:
      return {
        ...state,
        updateLoading: true,
      }
    case type.ADD_PRE_ASSESSMENT_DATA_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      }

    case type.ADD_PRE_ASSESSMENT_DATA_FALIURE:
      return {
        ...state,
        updateLoading: false,
        error: action.message
      }

    case type.UPDATE_PRE_ASSESSMENT:
      return {
        ...state,
        updateLoading: true,
      }

    case type.UPDATE_PRE_ASSESSMENT_DATA_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      }

    case type.UPDATE_PRE_ASSESSMENT_DATA_FALIURE:
        return {
          ...state,
          updateLoading: false,
          error: action.message
        }


    //download PDF
    case type.DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF:
      return {
        ...state,
        downloadLoader: true,
      }

    case type.DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_SUCCESS:
      return {
        ...state,
        downloadLoader: false,
      }

    case type.DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF_FALIURE:
      return {
        ...state,
        downloadLoader: false,
      }


    default:
      return state
  }
}

export function wellbeingReportReducer(state = {
  wellbeingReport:{},
  fullWellbeingReport:[],
  editWellbeingReportData: {},
  sections: [],
  finalSubmit: false,
  loading: false,
  downloadLoader: false,
  updateLoader: false
}, action) {
  switch (action.type) {
    //get
    case type.WELLBEING_REPORT_LOADING:
      return {
        ...state,
        loading: true,
      }

    case type.WELLBEING_REPORT_SUCCESS:
      let currentIndex = action.payload?.all_report_ids?.findIndex(x => (x.user_self_assessment_id === action.payload?.user_self_assessment_id) || (x.user_self_assessment_id === action.data?.user_self_assessment_id))
      if(currentIndex==-1)
        currentIndex= action.payload?.reopened_sections_ids?.length ? 1 : 0
      return {
        ...state,
        wellbeingReport: {...action.payload, filled: true, currentIndex},
        loading: false,
      }

    case type.CLEAR_WELLBEING_REPORT:
      return {
        ...state,
        wellbeingReport: {},
        loading: false,
      }

    case type.WELLBEING_REPORT_FAILURE:
      return {
        ...state,
        wellbeingReport:{all_report_ids: state.wellbeingReport.all_report_ids, filled: false},
        error: action.message,
        loading: false,
      }
    
    case type.FULL_WELLBEING_REPORT_SUCCESS:
      return {
        ...state,
        fullWellbeingReport: action.payload,
        loading: false,
      }

    case type.FULL_WELLBEING_REPORT_FAILURE:
      return {
        ...state,
        fullWellbeingReport: [],
        error: action.message,
        loading: false,
      }
    
    case type.GET_WELLBEING_REPORT_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editWellbeingReportData: action.payload,
        loading: false,
      }

    case type.GET_WELLBEING_REPORT_EDIT_DATA_FAILURE:
      return {
        ...state,
        error: action.message,
        loading: false,
      }

    case type.CLEAR_WELLBEING_REPORT_EDIT_DATA:
      return {
        ...state,
        editWellbeingReportData: {},
      }

      case type.UPDATE_WELLBEING_REPORT_DATA_SUCCESS:
        return {
          ...state,
          editWellbeingReportData: action.payload,
          loading: false,
        }
  
      case type.UPDATE_WELLBEING_REPORT_DATA_FAILURE:
        return {
          ...state,
          error: action.message,
          loading: false,
        }
      
      case type.FINAL_UPDATE_WELLBEING_REPORT_DATA_SUCCESS:
      return {
        ...state,
        finalSubmit: true,
        loading: false,
      }

      case type.SET_ASSESSMENT_SECTIONS:
      return {
        ...state,
        sections: action.payload,
        loading: false,
      }


      //download PDF
      case type.DOWNLOAD_WELLBEING_REPORT_PDF:
        return {
          ...state,
          downloadLoader: true,
        }
  
      case type.DOWNLOAD_WELLBEING_REPORT_PDF_SUCCESS:
        return {
          ...state,
          downloadLoader: false,
        }
  
      case type.DOWNLOAD_WELLBEING_REPORT_PDF_FALIURE:
        return {
          ...state,
          downloadLoader: false,
        }
      
      case type.OPEN_HEALTH_ASSESSMENT:
        return {
          ...state,
          updateLoader: true,
        }
  
      case type.OPEN_HEALTH_ASSESSMENT_SUCCESS:
        return {
          ...state,
          updateLoader: false,
        }
  
      case type.OPEN_HEALTH_ASSESSMENT_FALIURE:
        return {
          ...state,
          updateLoader: false,
        }
      
       default:
      return state
  }
}

export function guestDetailsReducer(state = {
  guestDetails: null,
  loading: false,
  error: null
}, action) {

  switch (action.type) {
    case type.GUEST_DETAILS:
      return {
        ...state,
        loading: true,
      }
      
    case type.GUEST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        guestDetails: action.payload
      }
      
    case type.GUEST_DETAILS_FAILURE:
      return {
        ...state,
        guestDetails: null,
        loading: false,
        error: action.message
      }

    case type.UPDATE_GUEST_DOB:
      return state
      
    case type.UPDATE_GUEST_DOB_SUCCESS:
      return {
        ...state,
        loading: false,
        guestDetails: {
          ...state.guestDetails,
          DOB: action.payload.DOB
        }
      }
      
    case type.UPDATE_GUEST_DOB_FALIURE:
      return state

    default:
      return state
  }
}

export function preparatoryDietReducer(state = {
  loading: false,
  updateLoader: false,
  preparatoryDietData:{},
  error: null
}, action) {
  switch (action.type) {
    //get
    case type.GET_PREPARATORY_DIET_DATA:
      return {
        ...state,
        loading: true,
      }
    case type.GET_PREPARATORY_DIET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        preparatoryDietData: action.payload
      }

    case type.GET_PREPARATORY_DIET_DATA_FALIURE:
        return {
          ...state,
          preparatoryDietData: null,
          loading: false,
          error: action.message
        }
    
    case type.DEL_PREPARATORY_DIET_DATA_SUCCESS:
      return {
        ...state,
        preparatoryDietData: {}
      }


    case type.UPDATE_PREPARATORY_DIET_DATA:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_PREPARATORY_DIET_DATA_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      }

    case type.UPDATE_PREPARATORY_DIET_DATA_FALIURE:
        return {
          ...state,
          updateLoader: false,
        }

    default:
      return state
  }
}

export function consultationNotesReducer(state = {
  loading: false,
  updateLoader: false,
  previousNoteLoading: false,
  consultationNotesData:[],
  programmeName: "",
  followUpTimeline: "",
  notesType: null,
  previousNote: null,

  loadingLabTests: false,
  labTests: null,
  
  error: null
}, action) {
  switch (action.type) {
    case type.CLEAR_CONSULTATION_NOTES_DATA:
      return {
        ...state,
        consultationNotesData:[],
        programmeName: "",
        followUpTimeline: "",
      }
    //get
    case type.GET_CONSULTATION_NOTES_DATA:
      return {
        ...state,
        loading: true,
      }
    case type.GET_CONSULTATION_NOTES_DATA_SUCCESS:
      return {
        ...state,
        consultationNotesData: action.payload.list,
        programmeName: action.payload.program_name,
        followUpTimeline: action.payload.follow_up_timeline,
        loading: false,
      }
    case type.GET_CONSULTATION_NOTES_DATA_FALIURE:
        return {
          ...state,
          consultationNotesData: [],
          programmeName: "",
          followUpTimeline: "",
          loading: false,
          error: action.message
        }

    
    case type.GET_NOTES_TYPE_LIST_SUCCESS:
      return {
        ...state,
        notesType: action.payload
      }
    
    case type.DEL_CONSULTATION_NOTES_DATA_SUCCESS:
      let index = state.consultationNotesData?.findIndex(item=>item.notice_type==action.payload?.noteType)
      let key = state.consultationNotesData?.find(item=>item.notice_type==action.payload?.noteType)
      let newData = key?.list?.filter(item => item.note_id !== action.payload?.noteId)
      
      if(!newData.length)
        state.consultationNotesData.splice(index, 1);
      else
        state.consultationNotesData[index]['list'] = newData
      return {...state};

      case type.UPDATE_CONSULTATION_NOTES_VISIBLE_STATUS_SUCCESS:{
        let data = state.consultationNotesData
        let index = data?.findIndex((item) => item?.note_id === action.payload?.noteId)
        if (index > -1)
          data[index]['is_guest_visible'] = action.payload.isVisible
        return {
          ...state,
          consultationNotesData: data
        }
      }

    case type.ADD_CONSULTATION_NOTES_DATA:
      return {
        ...state,
        updateLoader: true,
      }
    case type.ADD_CONSULTATION_NOTES_DATA_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      }
    case type.ADD_CONSULTATION_NOTES_DATA_FALIURE:
        return {
          ...state,
          updateLoader: false,
      }


    case type.UPDATE_CONSULTATION_NOTES_DATA:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_CONSULTATION_NOTES_DATA_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      }
    case type.UPDATE_CONSULTATION_NOTES_DATA_FALIURE:
        return {
          ...state,
          updateLoader: false,
        }

    case type.GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE:
      return {
        ...state,
        previousNoteLoading: true,
      }
    case type.GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_SUCCESS:
      return {
        ...state,
        previousNoteLoading: false,
        previousNote: action.payload
      }
    case type.GET_PREVIOUS_CONSULTATION_NOTE_BY_TYPE_FALIURE:
        return {
          ...state,
          previousNote: null,
          previousNoteLoading: false,
          error: action.message
        }


    case type.GET_LAB_TESTS:
      return {
        ...state,
        loadingLabTests: true,
      }
    case type.GET_LAB_TESTS_SUCCESS:
      return {
        ...state,
        loadingLabTests: false,
        labTests: action.payload
      }
    case type.GET_LAB_TESTS_FALIURE:
        return {
          ...state,
          labTests: null,
          loadingLabTests: false,
          error: action.message
        }
    default:
      return state
  }
}

export function sessionReducer(state = {
  loading: false,
  updateLoader: false,
  sessionData:[],
  singleSession: null,
  error: null
}, action) {
  switch (action.type) {
    //get
    case type.GET_DAYWISE_USER_SCHEDULE:
      return {
        ...state,
        loading: true,
      }
    case type.GET_DAYWISE_USER_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        sessionData: action.payload
      }
    case type.GET_DAYWISE_USER_SCHEDULE_FALIURE:
        return {
          ...state,
          sessionData: [],
          loading: false,
          error: action.message
        }


    case type.ADD_SESSION_NOTES:
      return {
        ...state,
        updateLoader: true,
      }
    case type.ADD_SESSION_NOTES_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      }
    case type.ADD_SESSION_NOTES_FALIURE:
        return {
          ...state,
          updateLoader: false,
      }


    case type.VIEW_SESSION_NOTES:
      return {
        ...state,
        loading: true,
      }
    case type.VIEW_SESSION_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        singleSession: action.payload
      }
    case type.VIEW_SESSION_NOTES_FALIURE:
        return {
          ...state,
          singleSession: null,
          loading: false,
          error: action.message
        }
    default:
      return state
  }
}


export function testimonialReducer(state = {
  loading: false,
  testimonialData: null,
  error: null
}, action) {
  switch (action.type) {

    case type.VIEW_TESTIMONIAL_DATA:
        return {
            ...state,
            loading: true,
        }
    case type.VIEW_TESTIMONIAL_DATA_SUCCESS:
        return {
            ...state,
            loading: false,
            testimonialData:action.payload,
            error: null
        }
    case type.VIEW_TESTIMONIAL_DATA_FALIURE:
        return {
            ...state,
            loading: false,
            testimonialData: null
        }

    default:
      return state
  }
}

export function programmeFeedbackReducer(state = {
  loading: false,
  programmeFeedbackData: null,
  error: null
}, action) {
  switch (action.type) {

    case type.VIEW_PROGRAMME_FEEDBACK_DATA:
        return {
            ...state,
            loading: true,
        }
    case type.VIEW_PROGRAMME_FEEDBACK_DATA_SUCCESS:
        return {
            ...state,
            loading: false,
            programmeFeedbackData:action.payload,
            error: null
        }
    case type.VIEW_PROGRAMME_FEEDBACK_DATA_FALIURE:
        return {
            ...state,
            loading: false,
            programmeFeedbackData: null
        }

    default:
      return state
  }
}