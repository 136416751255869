import React from 'react'
import './RadioButton.scss'

function RadioButton({name, onChange=()=>{}, label, value, checked, disabled=false}) {
  return (
    <div className={`${checked ? 'fw-semi-bold' : ''} ${disabled ? 'text-muted' : ''} custom-radio-btn d-flex align-items-center`}>
        <input
        type='radio'
            className='me-2'
            value={value}
            checked={checked}
            name={name}
            onChange={onChange}
            style={{accentColor: '#74613C'}}
            disabled={disabled}
        />
        <div >
            {label}
        </div>
    </div>
  )
}

export default RadioButton