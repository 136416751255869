import { CREATE_PASSWORD, ENTER_PASSWORD } from "../../constants";

export function createPassword(data) {
    return {
        type: CREATE_PASSWORD,
        data
    };
}


export function enterPassword(data) {
    return {
        type: ENTER_PASSWORD,
        data
    };
}

