import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form} from 'formik'
import FormLayout from '../../components/From Layout/FormLayout'
import InputBox from '../../components/Form Components/InputBox/InputBox'
import lockIcon from '../../assets/images/lock.svg'
import confirmLockIcon from '../../assets/images/confirm-lock.svg'
import Button from '../../components/Form Components/Button'
import { emailValidation } from '../../validationSchema/emailMobileSchema'
import { login } from '../../redux/actions/AuthActions/LoginAction'
import "./CreatePassword.scss"
import * as routes from '../../routes/route'
import {ReactComponent as Arrow} from "../../assets/images/arrow-left.svg"
import { notify } from '../../utils/notification'
import { createPassword } from '../../redux/actions/AuthActions/PasswordAction'
import { formTypes } from '../../const/constants'


function CreatePassword() {
  const location = useLocation()
  const location_state = location.state;
  const {loginData} = useSelector(state=> state.loginReducer)
  const {forgotPasswordData} = useSelector(state=> state.forgotPasswordReducer)
  const isLogin = localStorage.getItem("UDID")
  let data = location_state.type===formTypes.FORGOT_PASSWORD ? forgotPasswordData : loginData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onsubmit = (values)=>{
    let {password, confirmPassword} = values
    if (!password || !confirmPassword) {
      notify("warning", "Please fill all details")
    } else if (password.length > 15 || password?.length < 6) {
      notify("warning", "Password must be 6-15 characters long")
    } else if (password !== confirmPassword) {
      notify("warning","Password and confirm password does not match")
    }else{
      dispatch(createPassword({
        ...values,
        ...data,
        onSuccessCallback: ()=>{
          navigate("/")
        }
      })) 
    }
  }

  useEffect(()=>{
    if(isLogin)
      navigate(-1)
    else if(!data)
        navigate(routes.LOGIN_WITH_EMAIL_ENDPOINT)
  }, [])
  

  //on direct accessing the url 
  if(!data || isLogin)
    return null;

  return (
    <div className="create-password-form">
      <FormLayout title="Create Password">
      
        <div className="muted-text">
          Set a string password to keep your account secure.
        </div>

        <Formik
          initialValues={{ password: '', confirmPassword: ''}}
          onSubmit={async(values,  { resetForm })=>{
            resetForm()                        
            onsubmit(values)
          }}
        >
          {
            (formik)=>{
              return (
                <Form name="login">
                  <div className="input-container">
                    <InputBox type="password" name="password" placeholder="Enter Password" iconwidth={'19px'} icon={lockIcon}/>
                    <InputBox type="password" name="confirmPassword" placeholder="Confirm Password" iconwidth={'23px'} icon={confirmLockIcon}/>
                  </div>

                  <div className="navigation">
                    <div className="text-end">
                      {/* <Link end="true" to={routes.LOGIN_WITH_MOBILE_ENDPOINT}>Login via Mobile Number</Link> */}
                    </div>
                    <div className="text-center">
                      <div className='d-flex align-items-center justify-content-between mt-1'>
                        <div className='text-primary fw-semi-bold d-flex align-items-center cursor-pointer' onClick={()=>navigate(-1)}><Arrow fill='#0d6efd' width={'13px'}/> &nbsp; Back</div>
                        <Button content="confirm" type="submit" disabled={!(formik.dirty && formik.isValid)}/>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </FormLayout>
    </div>
  )
}

export default CreatePassword