import moment from 'moment'
import { YogaDepartureConsultationField } from '../NoteFormsConstants'
import { useSelector } from 'react-redux'
import targetIcon from '../../../../assets/images/target.svg'


export const ViewYogaDepartureConsultation = ({content, guestName, conductedBy})=>{
    const consultationNotesData = useSelector(state => state.consultationNotesReducer)

    return (
        <div className="my-3 ">
            <div className="rounded border d-sm-flex d justify-content-between bg-light p-2 mb-2">
                <div className='d-flex mb-2 mb-sm-0'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Conducted By:</div>
                    <div className="text-break white-space">{conductedBy || '-'}</div>
                </div>
                <div className='d-flex  '>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>
            <div className=" w-100 rounded my-4">
                <div className="fw-semi-bold">
                    Dear {guestName},
                </div>
                Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s Yoga Consultant during post-stay online follow-ups.  
                </div>
            <div className='border rounded overflow-hidden'>
                <div className="bg-light text-center  border-bottom text-brown py-2 fw-semibold">
                PROGRAMME & TREATMENT PLAN
                </div>
                <div className='p-2 p-md-3'>
                <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>
                    {
                        content?.data?.programme_and_treatment_plan?.objectives?.map((item, index)=>{
                                return (
                                    <div className={index===0 ? '': ' mt-4 '} key={index}>
                                        <div className='border bg-light fw-semibold text-secondary p-2 text-uppercase'>                                             
                                            <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(index+1)?.toString()?.padStart(2, 0)}
                                        </div>                                        <table className='p-2 w-100'>
                                            <div>
                                            {
                                                YogaDepartureConsultationField?.objectives?.map((item, subIndex)=>(
                                                    <div className='row m-0' key={subIndex}>
                                                        <div className="fw-semi-bold border p-2 mr-2 col-12 col-md-4 col-lg-3">{item?.label}</div>
                                                        <div className="text-break border col-12 col-md-8 col-lg-9 p-2 white-space">{content.data?.programme_and_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</div>
                                                    </div>
                                                    ))
                                            }
                                            </div>
                                        </table>
                                    </div>
                                )
                            })
                        }
                        
                </div>
            </div>
            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center  border-bottom text-brown py-2 fw-semibold">
                    HEALTH ASSESSMENT
                </div>
                <div className="overflow-x-auto">
                    <div  style={{minWidth: '550px'}}>
                        <div className="row m-0 fw-semibold" >
                            <div className="p-2 border col-3">Parameter</div>
                            <div className='p-2 border col-4'>
                                On Arrival
                            </div>
                            <div className='p-2 border col-5'>
                                On Departure
                            </div>
                        </div>
                            {
                                YogaDepartureConsultationField?.healthAssessment?.map((item, index)=>{
                                    return (
                                        <div className="row m-0" key={index}>
                                            <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">{item.label}</div>
                                            <div className='border border-bottom-0 p-2 col-4'>
                                                {content?.data?.health_assessment?.[item.key]?.arrival_reading || '-'}
                                            </div>
                                            <div className='border border-bottom-0 p-2 col-5'>
                                                {content?.data?.health_assessment?.[item.key]?.value || '-'}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
                </div>
            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center  border-bottom text-brown py-2 fw-semibold">
                RECOMMENDATIONS AND FOLLOW UPS
                </div>
                <div className="overflow-x-auto">
                    <div  style={{minWidth: '800px'}}>
                        <div className="row m-0 fw-semibold" >
                        <div className="p-2 border col-2">Daily Routine</div>
                        <div className="p-2 border col-4">Details</div>
                            <div className='p-2 border col-2'>
                            Time of practice
                            </div>
                            <div className="p-2 border col-2">Duration/Repetition</div>
                            <div className='p-2 border col-2'>
                                Frequency
                            </div>
                        </div>
                            {
                                YogaDepartureConsultationField.recommendationsAndFollowUps?.map((item, index)=>{
                                    return (
                                        <div className="row w-100 m-0" key={index}>
                                            <div className=" border border-bottom-0 p-2 col-2">{item.label || '-'}</div>
                                            <div className=" border border-bottom-0 p-2 col-4">{content?.data?.recommendations_and_follow_ups?.[item.key]?.details || '-'}</div>
                                            <div className=" border border-bottom-0 p-2 col-2">{content?.data?.recommendations_and_follow_ups?.[item.key]?.time || '-'}</div>
                                            <div className='col-2 border border-bottom-0 p-2 '>
                                                {content?.data?.recommendations_and_follow_ups?.[item.key]?.duration|| '-'}
                                            </div>
                                            <div className='col-2 border border-bottom-0 p-2 '>
                                                <div className="data-input">
                                                    {content?.data?.recommendations_and_follow_ups?.[item.key]?.frequency || '-'}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
            </div>

            <div className="border rounded my-3 overflow-hidden">
                <div className="bg-light text-brown text-center py-2 fw-semibold">
                FOLLOW UP PROGRAMME
                </div>
                <div className=' '>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '30%'}}>Follow up Consultation:</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content?.data?.follow_up_programme?.consultation || '-'}</div>
                    </div>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '30%'}}>Follow up Visit (Programme):</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content?.data?.follow_up_programme?.programme || '-'}</div>
                    </div>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '30%'}}>Follow up Visit (Timeline):</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content?.data?.follow_up_programme?.timeline || '-'}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
