import moment from 'moment'
import { DEPARTURE, LabTestsField, LabTestsJSON, VitalsCheckField } from '../NoteFormsConstants'
import { useEffect, useRef, useState } from 'react'
import { parseJSON } from '../../../../utils/helpers/universalFunctions';
import dotIcon from '../../../../assets/images/3dots.svg'
import editBtn from '../../../../assets/images/edit.svg'
import deleteBtn from '../../../../assets/images/delete.svg'
import './ViewVitalsCheck.scss'
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { useDispatch } from 'react-redux';
import { deleteConsultationNotes } from '../../../../redux/actions/GuestDataActions/ConsultationNotesActions';
import { Link, useNavigate } from 'react-router-dom';
import { EDIT_CONSULTATION_NOTES_ENDPOINT } from '../../../../routes/route';
import {ReactComponent as FileIcon} from '../../../../assets/images/file.svg'

export const ViewVitalsCheck = ({content, guestId, userProgramId})=>{
    const [title, setTitle] = useState([]);
    const [data, setData] = useState([]);
    const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;
    const userData = JSON.parse(localStorage.getItem('UserData'));
    const canWriteNote = (permissions?.consultation_notes?.permissions || '').includes('write')
    const canWriteDietNote = (permissions?.dietary_notes?.permissions || '').includes('write')
  

    useEffect(()=>{
        if(content){
            setData(prev=>{
                let oldData = JSON.parse(JSON.stringify(content));
                oldData.forEach((item)=>{
                    const notesDetail = item.notes_detail ? parseJSON(item.notes_detail) : null;
                    item.notes_detail = notesDetail;  
                })
                return oldData;
            }) 
        }
    }, [content])
    
    return (
        <div className="my-3 vital-checks-form rounded-3 border border-2" style={{ overflow: 'auto', maxHeight: '500px', borderWidth: '2px'}}>
            <table className='m-0' style={{ width: '100%', tableLayout: 'fixed', borderSpacing: 0, borderCollapse: 'separate'}}>
                <thead className='position-sticky  top-0 rounded' style={{backgroundColor: '#F8F9FA', zIndex: 1}}>
                    <tr>
                        <th className='p-3 py-2 align-top position-sticky top-0 border-end start-0' style={{backgroundColor: '#F8F9FA', zIndex: 3}}>Conducted By: </th>
                        {
                            data.map((item, index)=>{
                                return(
                                    <th key={index} className='p-3 py-2 fw-normal' style={{width: '200px', zIndex: 1}}>{`${data[index].first_name} ${data[index].last_name}` || '-'}</th>
                                )
                            })
                        }
                    </tr>
                    <tr>
                        <th className='p-3 py-2 align-top pt-0 border-bottom position-sticky top-0 start-0 border-end' style={{backgroundColor: '#F8F9FA', zIndex: 3}}>Date: </th>
                        {
                            data.map((item, index)=>{
                                const canWrite = (item.notes_type === "Dietary Notes" ? canWriteDietNote : canWriteNote) && item?.user_id === userData?.user_id

                                return(
                                    <th key={index} className='p-3 py-2 fw-normal border-bottom' style={{width: '200px', zIndex: 1}}>
                                        {data[index].notes_detail.date ? moment(data[index].notes_detail.date, 'YYYY-MM-DD').format('DD MMM, YYYY') : '-'}
                                        {canWrite && <ViewOptions note={item} guestId={guestId} userProgramId={userProgramId} />}
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        VitalsCheckField?.map((item, index)=>{
                            return (
                                <tr className='border-bottom' key={index}>
                                    <td className='p-3 py-2 fw-semi-bold border-end start-0' style={{ position: 'sticky', backgroundColor: '#F8F9FA' }}>{item.label || '-'}</td>
                                    {
                                        data.map((subItem, subIndex)=>{
                                            return(
                                                <td key={subIndex} className='p-3 text-break py-2 fw-normal border-start border-end'>
                                                    {
                                                        data[subIndex].notes_detail.data[item.key].value ?
                                                        `${data[subIndex].notes_detail.data[item.key].value} ${item.unit}`
                                                        :
                                                        "-"
                                                    }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th className='p-3 py-2 border-end position-sticky start-0 align-top' style={{ backgroundColor: '#F8F9FA' }}>Uploaded Files: </th>
                        {
                            data.map((item, index)=>{
                                return(
                                    <th key={index} className='p-3 py-2 bg-white align-top  fw-normal border-start border-end'>
                                        <div className="text-break ">
                                            {
                                            data[index].notes_doc.length>0 ? data[index].notes_doc?.map((subItem, subIndex)=>{
                                                return(
                                                <Link className='d-flex w-100 align-items-center text-decoration-none mb-2 fw-semi-bold' target="_blank"  to={data[index].notes_url[subIndex]} key={subIndex}>
                                                    <span className="flex-shrink-0"><FileIcon width={13} /></span> &nbsp;
                                                    <div className='overflow-hidden flex-shrink-1 text-nowrap text-truncate'>
                                                        {subItem?.split('-').slice(1).join('-')}
                                                    </div>
                                                </Link>
                                                )
                                            })
                        
                                            :
                                            '-'
                                            }
                                        </div>
                                    </th>
                                )
                            })
                        }
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

const ViewOptions = (({note, guestId, userProgramId})=>{
    const [deleteModal, setDeleteModal] = useState(false)
    const [open, setOpen] = useState(false)
    const dropRef = useRef(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const deleteNote  = (id, noteType, docs) =>{
        dispatch(deleteConsultationNotes({noteId: id, noteType, docs}))
        setDeleteModal(false)
      }

    const outsideClickHandler = (e) => {
        if (dropRef.current && !dropRef.current.contains(e.target)) {
            setOpen(false); 
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", outsideClickHandler);
        
        return () => {
            document.removeEventListener("mousedown", outsideClickHandler);
        };
    }, []);

    return (
        <div className='d-inline-block lab-test-menu'>
            <img className='ms-2 cursor-pointer' src={dotIcon} alt="" onClick={()=>setOpen(prev=> !prev)} />
            <div>
                {
                    open &&
                    <div className="menu-dropdown" ref={dropRef} id="myDropdown">
                        <button className="menu-item fw-semi-bold edit border-bottom w-100" onClick={()=>navigate(EDIT_CONSULTATION_NOTES_ENDPOINT, {state: { noteId: note?.note_id, guestId, userProgramId}})}>
                        <img src={editBtn} alt="edit-btn" className="" /> Edit
                        </button>
                        <button className="menu-item w-100 fw-semi-bold text-danger" onClick={()=>{setDeleteModal(true); setOpen(false)}}>
                            <img src={deleteBtn} alt="del-btn" className="" /> Delete
                        </button>
                    </div>
                }
            </div>
            <DeleteModal
                deleteModal={deleteModal} 
                setDeleteModal={setDeleteModal}
                name="this note"
                onDelete = {()=>deleteNote(note?.note_id, note?.notes_type, note.notes_doc) }
            />
        </div>
    )
})