import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import megaphoneIcon from '../../assets/images/pen.svg'
import './changeDOBModal.scss'
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import calendarIcon from '../../assets/images/calendarIcon.png'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  pb: 2
};

export default function ChangeDOBModal({warnModal, setWarnModal, selectedDate, onUpdate, setDOB, hide}) {
  const handleClose = () => setWarnModal(false);

  return (
    <div>
      <Modal
       sx={{".MuiBox-root": {
                border: "none", borderRadius: "10px", outline: "none"
            }
        }}
        open={warnModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="changeDOB-modal">
            <div className="icon">
              <img width={20} src={megaphoneIcon} alt="" />
            </div>
            <div className="my-4">
              <div className="title">Change Date Of Birth</div>
                <div className='d-flex align-items-center mt-3'>
                    {/* <div className="subtitle">
                        DOB: 
                    </div> */}
                    <div className='dateBlock my-2'>
                        {!selectedDate ?
                            <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">Select Date Of Birth</p></div>
                            : <div className="dayDateTextDiv" ><p className="dayDateText">{(moment(selectedDate, "YYYY-MM-DD").format('DD/MM/YYYY'))}</p></div>
                        }
                        <div className='datePickerDiv'>
                            <ReactDatePicker
                                selected={selectedDate}
                                onChange={date => { setDOB(moment(date).format('YYYY-MM-DD')); }}
                                popperPlacement={"bottom"}
                                customInput={
                                    <img src={calendarIcon} className="calIcon" alt="" />
                                }
                                showYearDropdown={true}
                                showMonthDropdown={true}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className='d-flex align-items-center mt-3'>
                    <div className="subtitle">
                        Age: 
                    </div>
                    <div>
                        {selectedDate ? `${moment().diff(moment(selectedDate), 'year')}`: "-"}
                    </div>
                        
                </div> */}
              </div>
              <div className="hr"></div>
              <div className="d-flex justify-content-between">
                <div className={`button text-danger`}onClick={()=>setWarnModal(false)}>Cancel</div>
                <div className="vhr"></div>
                <div className="button text-success" onClick={onUpdate}>Update</div>
              </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}