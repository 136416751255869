import moment from 'moment'
import { DietrayNoteField } from '../NoteFormsConstants'
  

export const ViewDietaryNotes = ({content, conductedBy})=>{
    return (
        <div>
            <div className={`d-flex mb-4 ${Array.isArray(content?.detox_diet_days) ? 'flex-column': 'flex-row'}`}>
                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dietary Detox Days:</div>
                <div className="text-break white-space">
                    {
                        Array.isArray(content?.detox_diet_days) ?
                        <div>
                            <div className="row mt-2 fw-semi-bold text-muted m-0">
                                <div className="col-4 border py-2">Detox Type</div>
                                <div className="col-4 border py-2">Diet Date</div>
                                <div className="col-4 border py-2">Diet Details</div>
                            </div>
                            {
                                content?.detox_diet_days?.map((item, index)=>{
                                return (
                                    <div className="row m-0" key={index}>
                                    <div className="col-4 border border-top-0 py-2">
                                        {content.detox_diet_days[index]?.detox_type || '-'}
                                    </div>
                                    <div className="col-4 border border-top-0 py-2">
                                        {content.detox_diet_days[index]?.diet_date ? moment(content.detox_diet_days[index]?.diet_date, 'YYYY-MM-DD').format('MMM DD, YYYY') : '-'}
                                    </div>
                                    <div className="col-4 border border-top-0 py-2">
                                        {content.detox_diet_days[index]?.diet_details || '-'}
                                    </div>
                                    </div>
                                )
                                })
                            }
                            {
                                content.liquid_diet_days &&
                                <div className="row m-0">
                                    <div className="col-4 border border-top-0 py-2">
                                        {'LQD'}
                                    </div>
                                    <div className="col-4 border border-top-0 py-2">
                                        {'-'}
                                    </div>
                                    <div className="col-4 border border-top-0 py-2">
                                        {content.liquid_diet_days || '-'}
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        content?.detox_diet_days
                    }
                </div>
            </div>
        {
            DietrayNoteField?.map((item, index)=>(
                <div className='d-flex mb-3' key={index}>
                    <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}:</div>
                    <div className="text-break white-space">{content[item.key] || '-'}</div>
                </div>
                ))
        }
        </div>
    )
}
