import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditYogaArrivalConsultation.scss'
import {ADD, YogaArrivalConsultationField, YogaArrivalConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import CheckBox from '../../../../../components/CheckBox/CheckBox'
import RadioButton from '../../../../../components/RadioButton/RadioButton'
import targetIcon from '../../../../../assets/images/target.svg'
import { useEffect } from 'react'

const Objectives = ({setKey, removeObjective, content, handleAddMore})=>{
    return (
        <div className="border w-100 rounded summary">
            <div className="heading py-3">
                SUMMARY ASSESSMENT AND TREATMENT PLAN
            </div>
            <div className='p-2 p-md-3'>
                <div className="sub-heading text-muted py-4">
                    Primary objectives for improvement
                </div>
                <div className="d-md-flex flex-md-wrap align-items-stretch justify-content-start">
                    {
                        content?.data?.summary_assessment_treatment_plan?.objectives?.map((subitem, subindex)=> {
                            return (
                                <div className="objective-box p-md-2 mb-3" key={subindex}>
                                    <div className='border rounded'>
                                        <div className="heading p-3 py-2 d-flex justify-content-between align-items-center">
                                        <div>
                                    <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(subindex+1)?.toString()?.padStart(2, 0)}
                                    </div>                                            <div className='remove-btn' onClick={()=>removeObjective(subindex)}>
                                                <RemoveBtn/>
                                            </div>
                                        </div>
                                        <div className="p-2 p-md-3">
                                        {
                                            YogaArrivalConsultationField?.objectives?.map((item, index)=>{
                                                return (
                                                    <div className="data-row d-md-flex" key={index}>
                                                        <div className="fw-semi-bold mt-2" style={{width: '200px'}}>{item.label}: </div>
                                                        <div className='data-input'>
                                                            <TextArea
                                                                placeholder={"Enter "}
                                                                onChange={value =>setKey(["summary_assessment_treatment_plan", "objectives", subindex, item.key], value)}
                                                                row={2}
                                                                value={content?.data?.summary_assessment_treatment_plan?.objectives?.[subindex]?.[item.key] || ''}/>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    
                    <div className="p-md-2 add-more-container"  onClick={handleAddMore}>
                        <div className="add-more ">
                            Add More Objectives
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export const AddEditYogaArrivalConsultation = ({content, setContent, conductedBy, type,  guestId, userProgramId, formType, uploadComponent})=>{
  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.summary_assessment_treatment_plan.objectives.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.summary_assessment_treatment_plan.objectives = [...updatedData.data.summary_assessment_treatment_plan.objectives, YogaArrivalConsultationJSON.data.summary_assessment_treatment_plan.objectives[0]]
            return updatedData;
        })
    }

    useEffect(()=>{
        if(formType===ADD){
            setContent(prev=>{
                let newData = {...prev}
                newData.date = moment().format("YYYY-MM-DD")
                return newData
            })
        }
    }, [])

    return (
      <div className='yoga-arrival-consultation-form'>
          <>
          <div className="d-flex py-2 mb-3 flex-column flex-md-row  rounded justify-content-between">
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3 mb-2 mb-md-0">Conducted By: </div>
                {/* <TextField
                    width={'300px'}
                    type={'text'}
                    placeholder='Enter name' 
                    value={content?.conducted_by || ''} 
                    onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                  /> */}
                  {conductedBy || '-'}
              </div>
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Date</div>
                <DatePicker
                  value={content.date ? new Date(content.date) : ''}
                  onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                  width={'300px'}
                />
              </div>
            </div>
            <div className="border w-100 rounded p-2 p-md-3">
                <div className="data-row">
                    <div className="title">Knowledge/experience level of yoga and pranayama. If yes, frequency of practice.</div>
                    <div className='data-input'>
                        <TextArea
                            row={3}
                            placeholder={"Enter "}
                            onChange={value =>setKey(["experience_in_yoga_pranayama"], value)}
                            value={content?.data?.experience_in_yoga_pranayama || ''}/>
                    </div>
                </div>
                <div className="data-row">
                    <div className="title mt-4">Knowledge/experience level of meditation/mindfullness. If yes, frequency of practice.</div>
                    <div className='data-input'>
                        <TextArea
                            row={3}
                            placeholder={"Enter "}
                            onChange={value =>setKey(["experience_in_meditation"], value)}
                            value={content?.data?.experience_in_meditation || ''}/>
                    </div>
                </div>
                <div className="data-row">
                    <div className="title mt-4">Forms of activity for physical fitness. Frequency</div>
                    <div className='data-input'>
                        <TextArea
                            row={3}
                            placeholder={"Enter "}
                            onChange={value =>setKey(["forms_of_activity"], value)}
                            value={content?.data?.forms_of_activity || ''}/>
                    </div>
                </div>
                <div>
                    <div className="title text-uppercase mt-5 mb-3">
                        Specific Areas of Discomfort:
                    </div>
                    <div className="overflow-x-auto pb-4">
                        <div style={{minWidth: '500px'}}>
                            <div className='row mb-3 m-0'>
                                <div className="px-1 col-4 col-md-2"></div>
                                <div className="px-1 col-4 col-md-5 text-muted fw-semi-bold">Frequency of discomfort</div>
                                <div className="px-1 col-4 col-md-5 text-muted fw-semi-bold">Known reasons</div>
                            </div>
                            {
                                YogaArrivalConsultationField?.areaOfDiscomfort?.map((item, index)=>{
                                    return (
                                        <div className='row m-0'>
                                            <div className="px-1 col-4 col-md-2 fw-semi-bold">
                                                <div className="d-flex align-items-center">
                                                    <span style={{width: '75px'}} className="d-inline-block me-2 mt-1">{item.label}</span>
                                                    <span className="d-inline-block">
                                                        <CheckBox
                                                        className={'mt-2 pt-1'}
                                                            initialchecked={content?.data?.area_of_discomfort?.[item.key]?.value==='Yes'}
                                                            onChange={(e)=>{setKey(["area_of_discomfort", item.key, "value"], content?.data?.area_of_discomfort?.[item.key]?.value==='No' ? "Yes": "No")}}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            
                                            <div className="px-1 col-4 col-md-5">
                                                <TextArea
                                                    row={2}
                                                    placeholder={"Enter "}
                                                    onChange={value =>setKey(["area_of_discomfort", item.key, "frequency"], value)}
                                                    value={content?.data?.area_of_discomfort?.[item.key]?.frequency || ''}/>
                                            </div>
                                            <div className="px-1 col-4 col-md-5">
                                                <TextArea
                                                    row={2}
                                                    placeholder={"Enter "}
                                                    onChange={value =>setKey(["area_of_discomfort", item.key, "reasons"], value)}
                                                    value={content?.data?.area_of_discomfort?.[item.key]?.reasons || ''}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <div className="title text-uppercase mt-4 mb-3">
                    Yogic Assessment:
                    </div>
                    <div className="overflow-x-auto pb-4">
                        <div style={{minWidth: '650px'}}>
                            <div className='row m-0 mb-3'>
                                <div className=" col-5 col-md-3"></div>
                                <div className=" col-2 col-md-3  text-muted fw-semi-bold">Beginner</div>
                                <div className=" col-2 col-md-3 text-muted fw-semi-bold text-break">Intermediate</div>
                                <div className=" col-2 col-md-3 text-muted fw-semi-bold">Advanced</div>
                            </div>
                            {
                                YogaArrivalConsultationField?.yogicAssessment?.map((item, index)=>{
                                    return (
                                        <div className='row m-0 '>
                                            <div className="col-5 col-md-3 mt-3 fw-semi-bold">{item.label}</div>
                                            <div className="col-2 col-md-3 mt-3">
                                                <RadioButton
                                                    name={item.key}
                                                    value={"beginner"}
                                                    checked={content?.data?.yogic_assessment?.[item.key]==="beginner"}
                                                    onChange={(e)=>{setKey(["yogic_assessment", item.key], e.target.value)}}
                                                />
                                            </div>
                                            <div className="col-2 col-md-3 mt-3">
                                                <RadioButton
                                                    name={item.key}
                                                    value={"intermediate"}
                                                    checked={content?.data?.yogic_assessment?.[item.key]==="intermediate"}
                                                    onChange={(e)=>{setKey(["yogic_assessment", item.key], e.target.value)}}
                                                />
                                            </div>
                                            <div className="col-2 col-md-3 mt-3">
                                                <RadioButton
                                                    name={item.key}
                                                    value={"advanced"}
                                                    checked={content?.data?.yogic_assessment?.[item.key]==="advanced"}
                                                    onChange={(e)=>{setKey(["yogic_assessment", item.key], e.target.value)}}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
                <hr />
                <div className="my-2">
                    {
                        YogaArrivalConsultationField?.others?.map((item, index)=>{
                            return (
                                <div className='row'>
                                    <div className="col-12 col-md-3 mt-1 fw-semi-bold">
                                    <span  className="d-inline-block me-2 mt-1">{item.label}: </span>
                                    </div>
                                    
                                    <div className="col-12 col-md-9">
                                        <TextArea
                                            row={2}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["others", item.key], value)}
                                            value={content?.data?.others?.[item.key] || ''}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <hr/>
                <div>
                    <div className="title text-uppercase mt-4 mb-1">
                    Breathing Pattern:
                    </div>
                    {
                        YogaArrivalConsultationField?.breathingPatterns?.map((item, index)=>{
                            return (
                                <div className='row'>
                                    <div className="col-7 col-md-4 mt-3 fw-semi-bold">{item.label}</div>
                                    <div className="col-2 mt-3">
                                        <RadioButton
                                            name={item.key}
                                            label={"Yes"}
                                            value={"Yes"}
                                            checked={content?.data?.breathing_pattern?.[item.key]==="Yes"}
                                            onChange={(e)=>{setKey(["breathing_pattern", item.key], e.target.value)}}
                                        />
                                    </div>
                                    <div className="col-2 mt-3">
                                        <RadioButton
                                            name={item.key}
                                            label={"No"}
                                            value={"No"}
                                            checked={content?.data?.breathing_pattern?.[item.key]==="No"}
                                            onChange={(e)=>{setKey(["breathing_pattern", item.key], e.target.value)}}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='mt-3 row'>
                        <div className='fw-semi-bold col-12 col-md-4'> Inhale : Exhale Ratio <br/> <span className='text-muted'>(after few rounds of normal breathing)</span></div>
                        <div className="col-12 col-md-8">
                            <TextArea
                                row={2}
                                placeholder={"Enter "}
                                onChange={value =>setKey(["breathing_pattern", "inhale_exhale_ratio"], value)}
                                value={content?.data?.breathing_pattern?.inhale_exhale_ratio || ''}/>
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <div className="title text-uppercase mt-4 mb-1">
                    Mental & Emotional Well-being
                    </div>
                    <div className="text-muted">How do you approach and manage recurring patterns or triggers?</div>
                    {
                        YogaArrivalConsultationField?.mentalEmotionalWellbeing?.map((item, index)=>{
                            return (
                                <div className='row'>
                                    <div className="col-10 col-md-5 mt-3 fw-semi-bold">{item.label}</div>
                                    <div className="col-2 mt-3">
                                    <CheckBox
                                            initialchecked={content?.data?.mental_emotional_wellbeing?.[item.key]==='Yes'}
                                            onChange={(e)=>{setKey(["mental_emotional_wellbeing", item.key], content?.data?.mental_emotional_wellbeing?.[item.key]==='No' ? "Yes": "No")}}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='mt-3 row'>
                        <div className='fw-semi-bold col-12 col-md-5'> Any other points to share</div>
                        <div className="col-12 col-md-7">
                            <TextArea
                                row={3}
                                placeholder={"Enter "}
                                onChange={value =>setKey(["mental_emotional_wellbeing", "other_points"], value)}
                                value={content?.data?.mental_emotional_wellbeing?.other_points || ''}/>
                        </div>
                    </div>

                </div>
            </div>
            <div className='text-danger alert alert-danger py-2 fw-semi-bold mt-5 text-center mb-2'>
                ** This following section is displayed to the guest, Please do not leave blank. **
            </div>
            <Objectives
                content={content}
                removeObjective={removeObjective}
                setKey={setKey}
                handleAddMore={handleAddMore}
            />
          </>
          <div className="upload-div">
          {uploadComponent}
          </div>
      </div>
    )
}