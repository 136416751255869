import {
    LOGIN,
    LOGIN_VERIFY_OTP,
    LOGIN_RESEND_OTP,
    CLEAR_LOGIN_DATA,
} from '../../constants';


export function login(data) {
    return {
        type: LOGIN,
        data,
    };
}


export function loginVerifyOTP(data) {
    return {
        type: LOGIN_VERIFY_OTP,
        data,
    };
}

export function loginResendOTP(data) {
    return {
        type: LOGIN_RESEND_OTP,
        data,
    };
}
