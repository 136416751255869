import { useEffect, useState } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditLabTests.scss'
import { ADD, DEPARTURE, LabTestsField, OldLabTestsField } from '../../NoteFormsConstants'
import { useDispatch, useSelector } from 'react-redux'
import { getConsultationNotes, getLabTests } from '../../../../../redux/actions/GuestDataActions/ConsultationNotesActions'
import { generateKey, parseJSON } from '../../../../../utils/helpers/universalFunctions'
import {ReactComponent as FileIcon} from '../../../../../assets/images/file.svg'
import { CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import MultiSelect from '../../../../../components/MultiSelect/MultiSelect'

export const AddEditLabTests = ({content, setContent, conductedBy, type, formType,  guestId, userProgramId, uploadComponent})=>{
  return (
    content.data.is_new_form ?
    <NewForm 
      content={content}
      setContent={setContent}
      conductedBy={conductedBy}
      typr={type}
      formType={formType}
      guestId={guestId}
      userProgramId={userProgramId} 
      uploadComponent={uploadComponent}
    />
    :
    <OldForm 
      content={content}
      setContent={setContent}
      conductedBy={conductedBy}
      typr={type}
      formType={formType}
      guestId={guestId}
      userProgramId={userProgramId} 
      uploadComponent={uploadComponent}
    />
  )
}

export const NewForm = ({content, setContent, conductedBy, type, formType,  guestId, userProgramId, uploadComponent})=>{
  const dispatch = useDispatch()
  const[arrivalDataFiles, setArrivalDataFiles] = useState({})
  const {consultationNotesData, loading, loadingLabTests, labTests} = useSelector(state=> state.consultationNotesReducer)

  const setLabForm = (keys, value)=>{
    setContent(prev=>{
      let updatedData = { ...prev}
      keys.reduce((acc, key, index) => {
          if (index === keys.length - 1) {
            acc[key] = value; 
          } else {
              if(Array.isArray(acc[key])){
                  acc[key] = [...acc[key] ]
              }else{
                  acc[key] = { ...acc[key] };
              }
          }
          return acc[key];
        }, updatedData.data);
      return updatedData;
    })
  }

  const handleMultipleValueFilter = (selectedOptions)=>{
    setContent(prev=>{
      const updatedData = {...prev};
      let updatedDynamicFields = [...updatedData.data.dynamic_fields];

      selectedOptions.forEach((option) => {
        const index = updatedDynamicFields.findIndex((item) => item.label === option.value);
        if (index === -1) {
          let newField = {
            label: option.label,
            value: "",
            unit: option.unit,
            order_by: option.order_by
          }
          updatedDynamicFields = [...updatedDynamicFields, newField]
        }
      });

      const fieldsToRemove = selectedOptions.map((option) => option.value);
      updatedDynamicFields = updatedDynamicFields.filter((item) => fieldsToRemove.includes(item.label));
      updatedData.data.dynamic_fields = updatedDynamicFields
      return updatedData;
    })
  }

    useEffect(()=>{
      if(guestId && userProgramId && type===DEPARTURE && formType===ADD){
          dispatch(getConsultationNotes({guestId, userProgramId, noteType: "Lab Tests - Arrival"}))
      }
      dispatch(getLabTests())
    },[])

    useEffect(()=>{
        if(type===DEPARTURE){
          let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail)
          if( formType === ADD){
            setContent(prev=>{
              let newData= {...prev};
              newData.date = moment().format("YYYY-MM-DD")

              if(notes_detail){
                for (let key in newData.data) {
                  newData.data[key].arrival_reading = notes_detail?.data[key]?.value
                }
              }
              return newData;
            })
            if(notes_detail)
              setArrivalDataFiles(p=>{
                return {
                  notes_doc : consultationNotesData?.[0]?.list?.[0]?.notes_doc,
                  notes_url : consultationNotesData?.[0]?.list?.[0]?.notes_url
                }
              })
          }
        }else{
            setContent(prev=>{
              let newData= {...prev};
              newData.date = moment().format("YYYY-MM-DD")
              return newData;
            })
        }
    },[consultationNotesData])

    return (
      <div>
        {
          loading ?
          <div className="loader mx-auto">
            <CircularProgress sx={{'color': '#74613C'}} size={20}/>
          </div>
          :
          <>
            <div className="d-flex py-2 mb-3 rounded justify-content-between">
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Conducted By: </div>
                {/* <TextField
                    width={'300px'}
                    type={'text'}
                    placeholder='Enter name' 
                    value={content?.conducted_by || ''} 
                    onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                  /> */}
                  {conductedBy || '-'}
              </div>
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Date</div>
                <DatePicker
                  value={content.date ? new Date(content.date) : ''}
                  onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                  width={'300px'}
                />
              </div>
            </div>
            <div className='border-top d-flex align-items-center border-bottom py-3 mb-4'>
                <div className='text-muted fw-semi-bold me-3'>Select Tests</div>
                <div style={{width: '400px'}}>
                  <MultiSelect
                    loading={loadingLabTests}
                    options={[
                      ...(labTests ? labTests?.data?.map((item)=>({
                            label: item.test_name,
                            value: item.test_name,
                            unit: item.unit,
                            order_by: item.order_by
                          })) : []
                          )
                      ]}
                      width={'100%'}
                      defaultValue={content.data.dynamic_fields?.map((item)=>({
                        label: item.label,
                        value: item.label
                      })) || []}
                      onClickOk={(values)=>{handleMultipleValueFilter(values)}}
                      hasSelectAllOption={false}
                  />
                </div>
              </div>
            <div className="d-flex p-3 border rounded justify-content-between w-100">
            {/* {
              type===DEPARTURE &&
              <div className='lab-tests-old-form'>
                  <div className="fw-semi-bold mb-4 text-uppercase">Arrival Data</div>
                  <div>
                  <div className=' position-relative'>
                      {
                      LabTestsField?.map((item, index)=>(
                          <div className="data-row mb-4" key={index}>
                          <div className="title text-secondary">{item.label}</div>
                          <div className='data-input'>
                          {content?.data?.[item.key]?.arrival_reading || '-'} 
                          </div>
                          </div>
                      ))
                      }
                  </div>
                 </div>
              </div>
              } */}
              <div className="lab-tests-form  ">
                {
                  type===DEPARTURE && <div className="fw-semi-bold mb-4 text-uppercase">Departure Data</div>
                }
                {
                    LabTestsField?.map((item, index)=>{
                      return(
                        <div className="data-row mb-4" key={index}>
                          <div className="title">{item.label}</div>
                          <div className='data-input'>
                            <div className='input-container'>
                              <TextField 
                                  type={item.type}
                                  placeholder='Enter value' 
                                  value={content?.data?.["static_fields"]?.[item.key] || ''} 
                                  onChange={e=>setLabForm(["static_fields", item.key], e.target.value)}
                                  unit={item?.unit || ""}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })
                }

                {
                    content.data.dynamic_fields?.map((item, index)=>{
                      return(
                        <div className="data-row mb-4" key={index}>
                          <div className="title">{item.label}</div>
                          <div className='data-input'>
                            <div className='input-container'>
                              <TextField 
                                  type={item.type}
                                  placeholder='Enter value' 
                                  value={content?.data?.["dynamic_fields"]?.[index]?.value || ''} 
                                  onChange={e=>setLabForm(["dynamic_fields", index, "value"], e.target.value)}
                                  unit={item.unit}
                              />
                            </div>
                          </div>
                        </div>
                    )
                    })
                }
                {uploadComponent}
              </div>
              
            </div>
          </>
        }
      </div>
    )
}

export const OldForm = ({content, setContent, conductedBy, type, formType,  guestId, userProgramId, uploadComponent})=>{
  const dispatch = useDispatch()
  const[arrivalDataFiles, setArrivalDataFiles] = useState({})
  const {consultationNotesData, loading} = useSelector(state=> state.consultationNotesReducer)
  const setLabForm = (key, value)=>{
  }

    useEffect(()=>{
      if(guestId && userProgramId && type===DEPARTURE && formType===ADD){
          dispatch(getConsultationNotes({guestId, userProgramId, noteType: "Lab Tests - Arrival"}))
      }
    },[])

    useEffect(()=>{
        if(type===DEPARTURE){
          let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail)
          if( formType === ADD){
            setContent(prev=>{
              let newData= {...prev};
              newData.date = moment().format("YYYY-MM-DD")

              if(notes_detail){
                for (let key in newData.data) {
                  newData.data[key].arrival_reading = notes_detail?.data[key]?.value
                }
              }
              return newData;
            })
            if(notes_detail)
              setArrivalDataFiles(p=>{
                return {
                  notes_doc : consultationNotesData?.[0]?.list?.[0]?.notes_doc,
                  notes_url : consultationNotesData?.[0]?.list?.[0]?.notes_url
                }
              })
          }
        }else{
            setContent(prev=>{
              let newData= {...prev};
              newData.date = moment().format("YYYY-MM-DD")
              return newData;
            })
        }
    },[consultationNotesData])

    return (
      <div>
        {
          loading ?
          <div className="loader mx-auto">
            <CircularProgress sx={{'color': '#74613C'}} size={20}/>
          </div>
          :
          <>
            <div className="d-flex py-2 mb-3 rounded justify-content-between">
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Conducted By: </div>
                {/* <TextField
                    width={'300px'}
                    type={'text'}
                    placeholder='Enter name' 
                    value={content?.conducted_by || ''} 
                    onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                  /> */}
                  {conductedBy || '-'}
              </div>
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Date</div>
                <DatePicker
                  value={content.date ? new Date(content.date) : ''}
                  onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                  width={'300px'}
                />
              </div>
            </div>
            <div className="d-flex  p-3 border rounded justify-content-between w-100">
            {
              type===DEPARTURE &&
              <div className='lab-tests-old-form'>
                  <div className="fw-semi-bold mb-4 text-uppercase">Arrival Data</div>
                  <div>
                  <div className=' position-relative'>
                      {
                      OldLabTestsField?.map((item, index)=>(
                          <div className="data-row mb-4" key={index}>
                          <div className="title text-secondary">{item.label}</div>
                          <div className='data-input'>
                          {content?.data?.[item.key]?.arrival_reading || '-'} 
                          </div>
                          </div>
                      ))
                      }
                  </div>
                 </div>
              </div>
              }
              <div className="lab-tests-form  ">
                {
                  type===DEPARTURE && <div className="fw-semi-bold mb-4 text-uppercase">Departure Data</div>
                }
                {
                    OldLabTestsField?.map((item, index)=>(
                    <div className="data-row mb-4" key={index}>
                        <div className="title">{item.label}</div>
                        <div className='data-input'>
                            {
                            item.type!=='textarea'?
                            <div className='input-container'>
                            <TextField 
                                type={item.type}
                                placeholder='Enter value' 
                                value={content?.data?.[item.key]?.value || ''} 
                                onChange={e=>setLabForm(item.key, e.target.value)}
                                unit={""}
                            />
                            </div>
                            :
                            <TextArea
                              placeholder={"Enter value"}
                              onChange={value =>setLabForm(item.key, value)}
                              value={content?.data?.[item.key] || ''}/>
                            }
                        </div>
                    </div>
                    ))
                }
                {uploadComponent}
                </div>
            </div>
          </>
        }
      </div>
    )
}