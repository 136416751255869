import React, { useEffect } from 'react'
import './ProgrammeFeedback.scss'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getProgrammeFeedback } from '../../redux/actions/GuestDataActions/ProgrammeFeedbackActions';
import GuestDetailsHeader from '../../components/GuestDetailsHeader/GuestDetailsHeader';
import { CircularProgress, Rating } from '@mui/material';
import moment from 'moment';

function ProgrammeFeedback() {
    const {loading, guestDetails} = useSelector(state=> state.guestDetailsReducer)
    const programmeFeedbackReducer = useSelector(state=> state.programmeFeedbackReducer)
    const location = useLocation();
    const dispatch = useDispatch();
    const {userProgramId, guestId} = location.state;

    useEffect(()=>{
        dispatch(getProgrammeFeedback({userProgramId, guestId}))
    }, [])

  return (
    <div>
        <div className="programme-feedback-container">
            <div className="container h-100">
                <div className="programme-feedback-heading">
                    Programme Feedback
                </div>           
                {   (programmeFeedbackReducer.loading || loading) && (guestId !== guestDetails?.user_id) ?
                        <div className="loader">
                            <CircularProgress sx={{'color': '#74613C'}}/>
                        </div>

                        :

                    <div className="pt-4 pb-5">
                        <div className="programme-feedback-content">
                            <GuestDetailsHeader guestId={guestId} />
                            {
                                programmeFeedbackReducer.programmeFeedbackData?.[0]?.is_feedback_filled ?
                                <div className="p-3">
                                    {
                                        programmeFeedbackReducer?.programmeFeedbackData?.map((section, index)=>{
                                            return(
                                                <div key={index}>
                                                    {
                                                        section?.questions?.map((question, index1)=>{
                                                            return(
                                                                <div className='question-box' key={`${index+index1}`}>
                                                                    <div className='question'> <span className='question-number'>{(question?.question_position).toString()?.padStart(2, 0)}.</span>  {question?.question_title} </div>
                                                                    <div> 
                                                                        {
                                                                            question?.question_options?.map((subQuestion,index2)=>{
                                                                                return(
                                                                                    <div className='subquestion-box' key={`${index+index1+index2}`}>
                                                                                        {subQuestion?.option_tag_name && <div className='mb-2'> {subQuestion?.option_tag_name} </div>}
                                                                                        
                                                                                        <div className={'answer-box'}>
                                                                                            {
                                                                                                subQuestion?.user_input === '1' &&
                                                                                                    <div className='text-answer'>{question?.answers?.[index2]?.user_input || '-'}</div>
                                                                                                    
                                                                                            }
                                                                                            <div className='rating-answer'>
                                                                                                    {
                                                                                                        subQuestion?.max_rating?
                                                                                                        // <div className='d-flex align-items-center'>{console.log(subQuestion)}
                                                                                                        //     {subQuestion?.user_input === '1' && <div className='text-brown fw-semi-bold me-3'>Rating: </div>}
                                                                                                        //     <Rating max={parseInt(subQuestion?.max_rating)} value={subQuestion?.user_input ? parseInt(question?.answers?.[index2]?.rating) : 0} disabled={true} />
                                                                                                        // </div>
                                                                                                        <div className='d-flex'>
                                                                                                            {
                                                                                                                Array.apply(null, {length: parseInt(subQuestion?.max_rating)}).map((i, index3)=>{
                                                                                                                    return(
                                                                                                                        <div className='radio-box' key={`${index+index1+index2+index3}`}>
                                                                                                                            <input type='radio' disabled={question?.answers?.[index2]?.rating != index3+1} checked={question?.answers?.[index2]?.rating == index3+1} onChange={()=>{}}/> &nbsp;
                                                                                                                            <span>{index3+1}</span>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                        :null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        } 
                                                                    </div>
                                                                    <hr className='my-5' />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }                                
                                </div>
                                :
                                <div className='mx-auto mt-5 fw-semi-bold'>
                                    Programme Feedback not filled yet.
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
  )
}

export default ProgrammeFeedback