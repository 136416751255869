import React, { useEffect, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import calendarIcon from '../../assets/images/calendarIcon.png'
import './DatePicker.scss'
import moment from 'moment';

function DatePicker({value, onChange, placeholder, width, maxDate, backgroundColor}) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const currentDateRef = useRef(null)
  const boxRef = useRef(null)

  const handleDateChange = (date) => {
    setCurrentDate(date)
    currentDateRef?.current?.setState(prev => ({ ...prev, open: false }))
    if(onChange){
      onChange(date.toISOString())
    }
  };


  useEffect(()=>{
    if(value){
      setCurrentDate(value)
    }
  }, [value])

  useEffect(() => {
    function handleClick (){
      currentDateRef?.current?.setOpen(true)
    }

    if (boxRef?.current) {
      boxRef.current.getElementsByClassName('react-datepicker-wrapper')[0].addEventListener('click', handleClick);
    }

    return () => {
      if (boxRef?.current) {
        boxRef.current.getElementsByClassName('react-datepicker-wrapper')[0]?.removeEventListener('click', handleClick);
      }
    };
  }, []);

  return (
    <div className='datePickerDiv-container' ref={boxRef}  style={{width, backgroundColor}}>
        <div className='flex-grow-1 datepicker-label'>
          {!value ?
          <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">Select</p></div>
          : <div className="dayDateTextDiv" ><p className="dayDateText">{(moment(value, "YYYY-MM-DD").format('DD/MM/YYYY'))}</p></div>
          }
        </div>
        <ReactDatePicker
            selected={currentDate}
            onChange={date => { handleDateChange(date); }}
            popperPlacement={"bottom"}
            customInput={
                <img src={calendarIcon} className="calIcon" alt="" />
            }
            {...maxDate && {maxDate: maxDate}}
            ref={currentDateRef}
            showYearDropdown={true}
            showMonthDropdown={true}
        />
    </div>
  )
}

export default DatePicker