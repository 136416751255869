import * as type from '../../constants';

const initialState = {
  loginData: null,
  userData: null,
  loading: false,
  error: null,
}

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case type.LOGIN_REQUEST_START:
      return {
        ...state,
        loading: true,
      }
    case type.LOGIN_REQUEST_END:
      return {
        ...state,
        loading: false,
      }
    case type.SET_LOGIN_DATA:
      return {
        ...state,
        loading: false,
        loginData: action.payload,
        error: null
      }

    case type.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
        userData: null,
        loginData: null
      }
    
    case type.SET_USER_DATA:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      }
    case type.CLEAR_USERDATA:
      return {
        ...state,
        loginData: null,
        userData: null,
        error: null
      }

        default:
          return state
        }
}