import { FORGOT_PASSWORD } from '../../constants';


export function forgotPassword(data) {
    return {
        type: FORGOT_PASSWORD,
        data
    };
}

