import { Fragment, useEffect, useState } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditFitnessDepartureConsultation.scss'
import { ADD, FitnessDepartureConsultationField, FitnessArrivalConsultationJSON, FitnessDepartureConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import { getConsultationNotes } from '../../../../../redux/actions/GuestDataActions/ConsultationNotesActions'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, getDateObjectFromTimeString, parseJSON } from '../../../../../utils/helpers/universalFunctions'
import { TimeSelector } from '../../../../../components/TimeSelector/TimeSelector'
import { CircularProgress } from '@mui/material'
import RadioButton from '../../../../../components/RadioButton/RadioButton'
import targetIcon from '../../../../../assets/images/target.svg'
import Select from "react-select";
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import { ReactComponent as Add } from '../../../../../assets/images/prelimAdd.svg'

const Objectives = ({setKey, removeObjective, content, handleAddMore})=>{
    return (
        <div className="border w-100 rounded mt-5 summary">
            <div className="heading py-3">
            PROGRAMME & TREATMENT PLAN
            </div>
            <div className='p-2 p-md-3'>
                <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.programme_and_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>
                <div className="d-md-flex flex-wrap align-items-stretch justify-content-start">
                    {
                        content?.data?.programme_and_treatment_plan?.objectives?.map((subitem, subindex)=> {
                            return (
                                <div className="objective-box p-md-2 mb-3" key={subindex}>
                                    <div className='border rounded'>
                                        <div className="heading p-3 py-2 d-flex justify-content-between align-items-center">
                                        <div>
                                            <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(subindex+1)?.toString()?.padStart(2, 0)}
                                        </div>                                            
                                            <div className='remove-btn' onClick={()=>removeObjective(subindex)}>
                                                <RemoveBtn/>
                                            </div>
                                        </div>
                                        <div className="p-2 p-md-3">
                                        {
                                            FitnessDepartureConsultationField?.objectives?.map((item, index)=>{
                                                return (
                                                    <div className="data-row d-flex" key={index}>
                                                        <div className="fw-semi-bold mt-2" style={{width: '220px'}}>{item.label}</div>
                                                        <div className='data-input'>
                                                            <TextArea
                                                                placeholder={"Enter "}
                                                                onChange={value =>setKey(["programme_and_treatment_plan", "objectives", subindex, item.key], value)}
                                                                row={2}
                                                                value={content?.data?.programme_and_treatment_plan?.objectives?.[subindex]?.[item.key] || ''}/>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    
                    <div className="add-more-container"  onClick={handleAddMore}>
                        <div className="add-more ">
                            Add More Objectives
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export const AddEditFitnessDepartureConsultation = ({content, setContent, conductedBy, formType,  guestId, userProgramId, guestName, uploadComponent})=>{
    const dispatch = useDispatch()
      const {consultationNotesData, programmeName, followUpTimeline, loading} = useSelector(state=> state.consultationNotesReducer)
      const programList = useSelector(state=> state.programReducer.programList)

  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.programme_and_treatment_plan.objectives.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.programme_and_treatment_plan.objectives = [...updatedData.data.programme_and_treatment_plan.objectives, FitnessArrivalConsultationJSON.data.summary_assessment_treatment_plan.objectives[0]]
            return updatedData;
        })
    }

    const handleAddMoreInRecommendation = (key) => {
        setContent(prev => {
            let updatedData = { ...prev }
            updatedData.data.recommendations_and_follow_ups[key] = [...updatedData.data.recommendations_and_follow_ups[key], FitnessDepartureConsultationJSON.data.recommendations_and_follow_ups[key][0]]
            return updatedData;
        })
    }

    const removeInRecommendation = (key, index) => {
        setContent(prev => {
            const updatedData = {
                ...prev,
                data: {
                    ...prev.data,
                    recommendations_and_follow_ups: {
                        ...prev.data.recommendations_and_follow_ups,
                        [key]: prev.data.recommendations_and_follow_ups[key].filter((_, i) => i !== index)
                    }
                }
            };
            return updatedData;
        });
    }


    useEffect(()=>{
        if(guestId && userProgramId && formType===ADD){
            dispatch(getConsultationNotes({guestId, userProgramId, noteType: "Fitness Arrival Consultation"}))
        }
      },[])

    useEffect(()=>{
        let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail);
        if(formType===ADD){ //have dummy JSON
        setContent(prev=>{
            let newData= {...prev};
            newData.date = moment().format("YYYY-MM-DD")
            if(notes_detail){
                newData.data.programme_and_treatment_plan = {
                    "objectives": notes_detail?.data?.summary_assessment_treatment_plan?.objectives
                }
    
                for (let key in notes_detail.data.cardio_test) {
                    newData.data.cardio_test[key].on_arrival = notes_detail.data.cardio_test[key] || "";
                }
                for (let key in notes_detail.data.strength) {
                    newData.data.strength[key].on_arrival = notes_detail.data.strength[key] || "";
                }
                for (let key in notes_detail.data.muscular_endurance) {
                    newData.data.muscular_endurance[key].on_arrival = notes_detail.data.muscular_endurance[key] || "";
                }
                for (let key in notes_detail.data.flexibility) {
                    newData.data.flexibility[key].on_arrival = notes_detail.data.flexibility[key] || "";
                }
            }
            return newData;
        })
        }
    },[consultationNotesData])

    return ( 
      <div className='fitness-departure-consultation-form'>
        {
          loading ?
          <div className="loader mx-auto">
            <CircularProgress sx={{'color': '#74613C'}} size={20}/>
          </div>
          :
          <>
            <>
            <div className="d-md-flex py-4 mb-2 border-top rounded justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3 mb-2 mb-md-0">Conducted By: </div>
                    {/* <TextField
                        width={'300px'}
                        type={'text'}
                        placeholder='Enter name' 
                        value={content?.conducted_by || ''} 
                        onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                    /> */}
                    {conductedBy || '-'}
                </div>
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3">Date</div>
                    <DatePicker
                    value={content.date ? new Date(content.date) : ''}
                    onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                    width={'300px'}
                    />
                </div>
                </div>
                <div className=" w-100 rounded mb-4">
                    <div className="fw-semi-bold">
                        Dear {guestName},
                    </div>
                    Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s Yoga Consultant during post-stay online follow-ups.                      </div>
                    <Objectives
                        content={content}
                        removeObjective={removeObjective}
                        setKey={setKey}
                        handleAddMore={handleAddMore}
                    />

            <div className="border w-100 my-3 rounded current-dietary-pattern " >
                <div className="heading py-3 text-uppercase">
                    Cardio (Treadmill) Test
                </div> 
                <div className="overflow-x-auto pb-4">
                    <div className='p-3 cardio-section'>
                        <div className="row m-0 pb-2 mb-3 border-bottom fw-semi-bold text-center text-muted">
                            <div className="col-2 p-1 bg-white ps-3 position-stick top-0 start-0" ></div>
                            <div className="col-5 p-1">On Arrival</div>
                            <div className="col-5 p-1">On Departure</div>
                        </div>
                        <div className="row m-0 pb-2 mb-3 border-bottom fw-semi-bold text-muted">
                            <div className="col-2 p-1 bg-white ps-3 position-stick top-0 start-0" > </div>
                            <div className="col-5 p-1">
                                <div className="row m-0 fw-semi-bold text-muted">
                                    <div className="col-3 p-1">Speed</div>
                                    <div className="col-3 p-1">Incline</div>
                                    <div className="col-3 p-1">Duration</div>
                                    <div className="col-3 p-1">HR</div>
                                </div>
                            </div>
                            <div className="col-5 p-1">
                                <div className="row m-0 fw-semi-bold text-muted">
                                    <div className="col-3 p-1">Speed</div>
                                    <div className="col-3 p-1">Incline</div>
                                    <div className="col-3 p-1">Duration</div>
                                    <div className="col-3 p-1">HR</div>
                                </div>
                            </div>
                        </div>
                        
                        {
                            FitnessDepartureConsultationField?.cardioTest?.map((item, index)=>{
                                return (
                                    <div className="row m-0 " key={index}>
                                        <div className="col-2 p-1 pt-2 ps-3 bg-white  fw-semi-bold position-stick top-0 start-0" >{item.label}</div>
                                        <div className="col-5 row m-0">
                                            <div className="col-3 p-1 pt-2">
                                                <TextField 
                                                    type={'numeric'}
                                                    maxLength="5"
                                                    placeholder='Enter ' 
                                                    unit={'KMPH'}
                                                    value={content?.data?.cardio_test?.[item.key]?.on_arrival?.speed || ''} 
                                                    onChange={e =>setKey(["cardio_test", item.key, "on_arrival", "speed"], e.target.value)}
                                                />
                                            </div>
                                            <div className="col-3 p-1 pt-2">
                                                <TextField 
                                                    type={'numeric'}
                                                    maxLength="5"
                                                    placeholder='Enter ' 
                                                    unit={'%'}
                                                    value={content?.data?.cardio_test?.[item.key]?.on_arrival?.incline || ''} 
                                                    onChange={e =>setKey(["cardio_test", item.key, "on_arrival", "incline"], e.target.value)}
                                                />
                                            </div>
                                            <div className="col-3 p-1 pt-2">
                                                <TextField 
                                                    type={'numeric'}
                                                    maxLength="5"
                                                    placeholder='Enter ' 
                                                    unit={'Min'}
                                                    value={content?.data?.cardio_test?.[item.key]?.on_arrival?.duration || ''} 
                                                    onChange={e =>setKey(["cardio_test", item.key, "on_arrival", "duration"], e.target.value)}
                                                />
                                            </div>
                                            <div className="col-3 p-1">
                                                <TextField 
                                                    type={'numeric'}
                                                    maxLength="5"
                                                    placeholder='Enter ' 
                                                    unit={'bpm'}
                                                    value={content?.data?.cardio_test?.[item.key]?.on_arrival?.hr || ''} 
                                                    onChange={e =>setKey(["cardio_test", item.key, "on_arrival", "hr"], e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-5 row m-0">
                                            <div className="col-3 p-1 pt-2">
                                                <TextField 
                                                    type={'numeric'}
                                                    maxLength="5"
                                                    placeholder='Enter ' 
                                                    unit={'KMPH'}
                                                    value={content?.data?.cardio_test?.[item.key]?.on_departure?.speed || ''} 
                                                    onChange={e =>setKey(["cardio_test", item.key, "on_departure", "speed"], e.target.value)}
                                                />
                                            </div>
                                            <div className="col-3 p-1 pt-2">
                                                <TextField 
                                                    type={'numeric'}
                                                    maxLength="5"
                                                    placeholder='Enter ' 
                                                    unit={'%'}
                                                    value={content?.data?.cardio_test?.[item.key]?.on_departure?.incline || ''} 
                                                    onChange={e =>setKey(["cardio_test", item.key, "on_departure", "incline"], e.target.value)}
                                                />
                                            </div>
                                            <div className="col-3 p-1 pt-2">
                                                <TextField 
                                                    type={'numeric'}
                                                    maxLength="5"
                                                    placeholder='Enter ' 
                                                    unit={'Min'}
                                                    value={content?.data?.cardio_test?.[item.key]?.on_departure?.duration || ''} 
                                                    onChange={e =>setKey(["cardio_test", item.key, "on_departure", "duration"], e.target.value)}
                                                />
                                            </div>
                                            <div className="col-3 p-1">
                                                <TextField 
                                                    type={'numeric'}
                                                    maxLength="5"
                                                    placeholder='Enter ' 
                                                    unit={'bpm'}
                                                    value={content?.data?.cardio_test?.[item.key]?.on_departure?.hr || ''} 
                                                    onChange={e =>setKey(["cardio_test", item.key, "on_departure", "hr"], e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="border w-100 mb-5 rounded current-dietary-pattern ">
                <div className="heading py-3 text-uppercase">
                    Strength
                </div> 
                <div className="overflow-x-auto pb-4">
                    <div className='p-3 cardio-section'>
                        <div className="row m-0 pb-2 mb-3 border-bottom fw-semi-bold text-center text-muted">
                            <div className="col-2 p-1 bg-white ps-3 position-stick top-0 start-0" ></div>
                            <div className="col-5 p-1">On Arrival</div>
                            <div className="col-5 p-1">On Departure</div>
                        </div>
                        <div className="row m-0 pb-2 mb-3 border-bottom fw-semi-bold text-muted">
                            <div className="col-2 p-1 bg-white ps-3 position-stick top-0 start-0" > </div>
                            <div className="col-5 p-1">
                                <div className="row m-0 fw-semi-bold text-muted">
                                    <div className="col-6 p-1">Weight</div>
                                    <div className="col-6 p-1">Reps</div>
                                </div>
                            </div>
                            <div className="col-5 p-1">
                                <div className="row m-0 fw-semi-bold text-muted">
                                    <div className="col-6 p-1">Weight</div>
                                    <div className="col-6 p-1">Reps</div>
                                </div>
                            </div>
                        </div>
                        {
                        FitnessDepartureConsultationField?.strength?.map((item, index)=>{
                            return (
                                <div className="row m-0 " key={index}>
                                    <div className="col-2 p-1 pt-2 ps-3 bg-white  fw-semi-bold position-stick top-0 start-0" >{item.label}</div>
                                    <div className="col-5 row m-0">
                                        <div className="col-6 p-1 pt-2">
                                            <TextField 
                                                type={'numeric'}
                                                maxLength="5"
                                                placeholder='Enter ' 
                                                unit={'Kg'}
                                                value={content?.data?.strength?.[item.key]?.on_arrival?.weight || ''} 
                                                onChange={e =>setKey(["strength", item.key, "on_arrival", "weight"], e.target.value)}
                                            />
                                        </div>
                                        <div className="col-6 p-1 pt-2">
                                            <TextField 
                                                type={'numeric'}
                                                maxLength="5"
                                                placeholder='Enter ' 
                                                value={content?.data?.strength?.[item.key]?.on_arrival?.reps || ''} 
                                                onChange={e =>setKey(["strength", item.key, "on_arrival", "reps"], e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-5 row m-0">
                                        <div className="col-6 p-1 pt-2">
                                            <TextField 
                                                type={'numeric'}
                                                maxLength="5"
                                                placeholder='Enter ' 
                                                unit={'Kg'}
                                                value={content?.data?.strength?.[item.key]?.on_departure?.weight || ''} 
                                                onChange={e =>setKey(["strength", item.key, "on_departure", "weight"], e.target.value)}
                                            />
                                        </div>
                                        <div className="col-6 p-1 pt-2">
                                            <TextField 
                                                type={'numeric'}
                                                maxLength="5"
                                                placeholder='Enter ' 
                                                value={content?.data?.strength?.[item.key]?.on_departure?.reps || ''} 
                                                onChange={e =>setKey(["strength", item.key, "on_departure", "reps"], e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
            <div className="border w-100 mb-5 rounded current-dietary-pattern ">
                <div className="heading py-3 text-uppercase">
                    Muscular Endurance
                </div> 
                <div className="overflow-x-auto pb-4">
                    <div className='p-3 cardio-section'>
                        <div className="row m-0 pb-2 mb-3 border-bottom fw-semi-bold text-center text-muted">
                            <div className="col-2 p-1 bg-white ps-3 position-stick top-0 start-0" ></div>
                            <div className="col-5 p-1">On Arrival</div>
                            <div className="col-5 p-1">On Departure</div>
                        </div>
                        <div className="row m-0 pb-2 mb-3 border-bottom fw-semi-bold text-muted">
                            <div className="col-2 p-1 bg-white ps-3 position-stick top-0 start-0" > </div>
                            <div className="col-5 p-1">
                                <div className="row m-0 fw-semi-bold text-muted">
                                    <div className="col-6 p-1">Unit</div>
                                    <div className="col-6 p-1">Measurement</div>
                                </div>
                            </div>
                            <div className="col-5 p-1">
                                <div className="row m-0 fw-semi-bold text-muted">
                                    <div className="col-6 p-1">Unit</div>
                                    <div className="col-6 p-1">Measurement</div>
                                </div>
                            </div>
                        </div>
                        {
                            FitnessDepartureConsultationField?.muscularEndurance?.map((item, index)=>{
                                return (
                                <div className="row m-0 " key={index}>
                                    <div className="col-2 p-1 pt-2 ps-3 bg-white  fw-semi-bold position-stick top-0 start-0" >{item.label}</div>
                                    <div className="col-5 row m-0">
                                        <div className="col-6 p-1 pt-2">
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["muscular_endurance", item.key, "on_arrival", "units"], value)}
                                                value={content?.data?.muscular_endurance?.[item.key]?.on_arrival?.units || ''}/>
                                        </div>
                                        <div className="col-6 p-1 pt-2">
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["muscular_endurance", item.key, "on_arrival", "measurement"], value)}
                                                value={content?.data?.muscular_endurance?.[item.key]?.on_arrival?.measurement || ''}/>
                                        </div>
                                    </div>
                                    <div className="col-5 row m-0">
                                        <div className="col-6 p-1 pt-2">
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["muscular_endurance", item.key, "on_departure", "units"], value)}
                                                value={content?.data?.muscular_endurance?.[item.key]?.on_departure?.units || ''}/>
                                        </div>
                                        <div className="col-6 p-1 pt-2">
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["muscular_endurance", item.key, "on_departure", "measurement"], value)}
                                                value={content?.data?.muscular_endurance?.[item.key]?.on_departure?.measurement || ''}/>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="border w-100 mb-5 rounded current-dietary-pattern ">
                <div className="heading py-3 text-uppercase">
                    Flexibility
                </div> 
                <div className="overflow-x-auto pb-4">
                    <div className='p-3 cardio-section'>
                        <div className="row m-0 pb-2 mb-3 border-bottom fw-semi-bold text-center text-muted">
                            <div className="col-2 p-1 bg-white ps-3 position-stick top-0 start-0" ></div>
                            <div className="col-5 p-1">On Arrival</div>
                            <div className="col-5 p-1">On Departure</div>
                        </div>
                        <div className="row m-0 pb-2 mb-3 border-bottom fw-semi-bold text-muted">
                            <div className="col-2 p-1 bg-white ps-3 position-stick top-0 start-0" > </div>
                            <div className="col-5 p-1">
                                <div className="row m-0 fw-semi-bold text-muted">
                                    <div className="col-6 p-1">Right Side</div>
                                    <div className="col-6 p-1">Left Side</div>
                                </div>
                            </div>
                            <div className="col-5 p-1">
                                <div className="row m-0 fw-semi-bold text-muted">
                                    <div className="col-6 p-1">Right Side</div>
                                    <div className="col-6 p-1">Left Side</div>
                                </div>
                            </div>
                        </div>
                        {
                            FitnessDepartureConsultationField?.flexibility?.map((item, index)=>{
                                return (
                                <div className="row m-0 " key={index}>
                                    <div className="col-2 p-1 pt-2 ps-3 bg-white  fw-semi-bold position-stick top-0 start-0" >{item.label}</div>
                                    <div className="col-5 row m-0">
                                        <div className="col-6 p-1 pt-2">
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["flexibility", item.key, "on_arrival", "right_side"], value)}
                                                value={content?.data?.flexibility?.[item.key]?.on_arrival?.right_side || ''}/>
                                        </div>
                                        <div className="col-6 p-1 pt-2">
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["flexibility", item.key, "on_arrival", "left_side"], value)}
                                                value={content?.data?.flexibility?.[item.key]?.on_arrival?.left_side || ''}/>
                                        </div>
                                    </div>
                                    <div className="col-5 row m-0">
                                        <div className="col-6 p-1 pt-2">
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["flexibility", item.key, "on_departure", "right_side"], value)}
                                                value={content?.data?.flexibility?.[item.key]?.on_departure?.right_side || ''}/>
                                        </div>
                                        <div className="col-6 p-1 pt-2">
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["flexibility", item.key, "on_departure", "left_side"], value)}
                                                value={content?.data?.flexibility?.[item.key]?.on_departure?.left_side || ''}/>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="border w-100 mt-5 rounded systematic">
                    <div className="heading py-3">
                        RECOMMENDATIONS AND FOLLOW UPS
                    </div>
                    <div className="overflow-x-auto p-3 pb-4">
                        <div className='recommendation-section'>    
                            <div className="row m-0 fw-semibold pb-2 border-bottom mb-3" >
                                <div className="col-2">Daily Routine</div>
                                <div className="col-4">Details</div>
                                <div className="col-2">Time of Practice</div>
                                <div className='col-2'>
                                Duration/Repetition 
                                </div>
                                <div className='col-2'>
                                    Frequency
                                </div>
                            </div>
                                {
                                    FitnessDepartureConsultationField?.recommendationsAndFollowUps?.map((item, index)=>{
                                        return (
                                            <Fragment key={index}>
                                                {
                                                    item?.isArray ?
                                                    <div className='title row m-0'>
                                                        <div className="col-12 text-muted fw-semi-bold">
                                                            {item.label}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            <div key={content?.data?.recommendations_and_follow_ups?.[item.key]?.length}>
                                                {
                                                    content?.data?.recommendations_and_follow_ups?.[item.key]?.map((subItem, subindex)=>{
                                                        return (
                                                        <div key={subindex}>
                                                            <div className="row w-100 m-0 mb-2" >
                                                                <div className="title mt-2 col-2"> <span className={`${item.isArray ? 'ps-2' : ''}`}>{item.isArray ? (subindex+1)?.toString()?.padStart(2, '0') : item.label}</span> </div>
                                                                <div className='col-4'>
                                                                    <div className="data-input">
                                                                        <TextArea
                                                                            row={2}
                                                                            placeholder={"Enter "}
                                                                            onChange={value =>setKey(["recommendations_and_follow_ups", item.key, subindex, "details"], value)}
                                                                            value={content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.details || ''}/>
                                                                    </div>
                                                                </div>
                                                                <div className='col-2 pt-2'>
                                                                    <TimeSelector
                                                                        width={'100%'}
                                                                        ampm={true}
                                                                        time={getDateObjectFromTimeString(content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.time) || ''}
                                                                        setTime={(value)=>setKey(["recommendations_and_follow_ups", item.key, subindex, "time"], moment(new Date(value)).format('HH:mm A')) }
                                                                        
                                                                    />
                                                                </div>
                                                                <div className='col-2'>
                                                                    <div className="data-input">
                                                                        <TextArea
                                                                            row={2}
                                                                            placeholder={"Enter "}
                                                                            onChange={value =>setKey(["recommendations_and_follow_ups", item.key, subindex, "duration"], value)}
                                                                            value={content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.duration || ''}/>
                                                                    </div>
                                                                </div>
                                                                <div className='col-2'>
                                                                    <div className="data-input  d-flex align-items-center">
                                                                        <TextArea
                                                                            row={2}
                                                                            placeholder={"Enter "}
                                                                            onChange={value =>setKey(["recommendations_and_follow_ups", item.key, subindex, "frequency"], value)}
                                                                            value={content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.frequency || ''}/>
                                                                        {
                                                                            item.isArray && <div className='remove-btn ms-2' onClick={() => {removeInRecommendation(item.key, subindex)}}>
                                                                            <RemoveBtn />
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                        </div>
                                                        </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            {
                                                item.isArray &&
                                                <div className='cursor-pointer mx-auto' style={{width: 'fit-content'}} onClick={() => {handleAddMoreInRecommendation(item.key)}}>
                                                    <Add />
                                                </div>
                                            }
                                            </Fragment>
                                        )
                                    })
                                }
                        </div>
                    </div>

                </div>

            <div className="border w-100 mt-5 mb-3 rounded current-dietary-pattern ">
                <div className="heading py-3">
                FOLLOW UP PROGRAMME
                </div>
                <div className='p-2 p-md-3'>
                    <div className='d-md-flex mb-2'>
                        <div className="fw-semi-bold mb-1 flex-shrink-0" style={{width: '230px'}}>Follow up Consultation:</div>
                        <div className="text-break flex-grow-1 white-space">
                            <TextArea
                                row={2}
                                placeholder={"Enter "}
                                onChange={value =>setKey(["follow_up_programme", "consultation"], value)}
                                value={content?.data?.follow_up_programme?.consultation || ''}/>
                        </div>
                    </div>
                    <div className='d-md-flex mb-2'>
                        <div className="fw-semi-bold mb-1 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Programme):</div>
                        <div className="text-break flex-grow-1 white-space">
                            <Select
                                className="name-select"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={
                                    programList ? programList?.map(item=> ({
                                        label: item.title,
                                        value: item.title
                                    }))
                                    : []
                                }
                                placeholder='Select'
                                value={
                                    content?.data?.follow_up_programme?.programme ?
                                    {
                                        label: content?.data?.follow_up_programme?.programme,
                                        value: content?.data?.follow_up_programme?.programme
                                    }
                                    :
                                    null
                                }
                                onChange={e =>setKey(["follow_up_programme", "programme"], e?.value || '')}
                            />
                        </div>
                    </div>
                    <div className='d-md-flex mb-2'>
                        <div className="fw-semi-bold mb-1 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Timeline):</div>
                        <div className="text-break flex-grow-1 white-space">
                            <TextArea
                                row={2}
                                placeholder={"Enter "}
                                onChange={value =>setKey(["follow_up_programme", "timeline"], value)}
                                value={content?.data?.follow_up_programme?.timeline || ''}/>
                        </div>
                    </div>
                </div>
            </div>
            </>
            <div className="w-50">
                {uploadComponent}
            </div>
          </>
        }
      </div>
    )
}