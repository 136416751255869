import moment from 'moment'
import { YogaArrivalConsultationField } from '../NoteFormsConstants'
import {ReactComponent as CheckedIcon} from '../../../../assets/images/checked.svg'
import {ReactComponent as UncheckedIcon} from '../../../../assets/images/unchecked.svg'
import React from 'react'
import targetIcon from '../../../../assets/images/target.svg'


export const ViewYogaArrivalConsultation = ({content, conductedBy})=>{
    return (
        <div className="my-3 ">
            <div className="rounded border d-sm-flex d justify-content-between bg-light p-2 mb-2">
                <div className='d-flex mb-2 mb-sm-0'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Conducted By:</div>
                    <div className="text-break white-space">{conductedBy || '-'}</div>
                </div>
                <div className='d-flex  '>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>
            <div className='border rounded'>
                <div className='p-2 p-md-3'>
                    <div className='mb-4'>
                        <div className='fw-semibold mb-1'>Knowledge/experience level of yoga and pranayama. If yes, frequency of practice. </div>
                        <div>{content?.data?.experience_in_yoga_pranayama || '-'}</div>
                    </div>
                    <div className='mb-4'>
                        <div className='fw-semibold mb-1'>Knowledge/experience level of meditation/mindfullness. If yes, frequency of practice. </div>
                        <div>{content?.data?.experience_in_meditation || '-'}</div>
                    </div>
                    <div className='mb-4'>
                        <div className='fw-semibold mb-1'>Forms of activity for physical fitness. Frequency </div>
                        <div>{content?.data?.experience_in_meditation || '-'}</div>
                    </div>
                    <hr />
                    <div className='my-3'>
                        <div className='fw-semibold mb-3 text-uppercase text-secondary'>Specific Areas of Discomfort: </div>
                        <div className="overflow-x-auto">
                            <div  style={{minWidth: '550px'}}>
                                <div className='row m-0'>
                                    <div className="border p-2 col-2"></div>
                                    <div className="border p-2 col-2"></div>
                                    <div className="border p-2 col-4 text-center text-muted fw-semi-bold">Frequency of discomfort</div>
                                    <div className="border p-2 col-4 text-center text-muted fw-semi-bold">Known reasons</div>
                                </div>
                                <div>
                                {
                                    YogaArrivalConsultationField?.areaOfDiscomfort?.map((item, index)=>(
                                        <div className='row m-0' key={index}>
                                            <div className="border border-top-0 col-2 p-2 fw-semi-bold">{item.label}</div>
                                            <div className="border border-top-0 col-2 text-center p-2">{content?.data?.area_of_discomfort?.[item.key]?.value || "No"}</div>
                                            <div className="border border-top-0 col-4 text-center p-2">{content?.data?.area_of_discomfort?.[item.key]?.frequency || '-'}</div>
                                            <div className="border border-top-0 col-4 text-center p-2">{content?.data?.area_of_discomfort?.[item.key]?.reasons || '-'}</div>
                                        </div>
                                        ))
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='mt-4'>
                        <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Yogic Assessment: </div>
                        <div className="overflow-x-auto">
                            <div  style={{minWidth: '550px'}}>
                                <div className='row m-0 mb-3'>
                                    <div className="col-4"></div>
                                    <div className="col-2  text-center text-muted fw-semi-bold">Beginner</div>
                                    <div className="col-2 text-center text-muted fw-semi-bold">Intermediate</div>
                                    <div className="col-2 text-center text-muted fw-semi-bold">Advanced</div>
                                </div>
                                <div>
                                {
                                    YogaArrivalConsultationField?.yogicAssessment?.map((item, index)=>(
                                            <div className='mb-2 m-0 row' key={index}>
                                                <div className="col-4 mt-2  fw-semi-bold">{item.label}</div>
                                                <div className="col-2 mt-2 text-center">{content?.data?.yogic_assessment?.[item.key]==='beginner' ? <CheckedIcon/> : <UncheckedIcon/> }</div>
                                                <div className="col-2 mt-2 text-center">{content?.data?.yogic_assessment?.[item.key]==='intermediate' ? <CheckedIcon/> : <UncheckedIcon/> }</div>
                                                <div className="col-2 mt-2 text-center">{content?.data?.yogic_assessment?.[item.key]==='advanced' ? <CheckedIcon/> : <UncheckedIcon/> }</div>
                                            </div>
                                        ))
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='mt-4'>
                        <div>
                        {
                            YogaArrivalConsultationField?.others?.map((item, index)=>(
                                    <div className='mb-2 row' key={index}>
                                        <div className="col-12 col-md-3 mt-2  fw-semi-bold">{item.label}: </div>
                                        <div className="col-12 col-md-9 mt-2 text-justify">{content?.data?.others?.[item.key] || '-' }</div>
                                     </div>
                                ))
                        }
                        </div>
                    </div>
                    <hr />
                    <div className='mt-4'>
                        <div className='fw-semibold mb-3  text-secondary text-uppercase'>Breathing Pattern: </div>
                        <div>
                        {
                            YogaArrivalConsultationField?.breathingPatterns?.map((item, index)=>(
                                <div className='mb-2 row' key={index}>
                                    <div className="col-9 col-md-3 mt-1  fw-semi-bold">{item.label}: </div>
                                    <div className="col-3 col-md-9 mt-2 ">{content?.data?.breathing_pattern?.[item.key] || '-'}</div>
                                </div>
                            ))
                        }
                        </div>
                        <div className='mb-2 row'>
                            <div className="col-12 col-md-3 mt-2  fw-semi-bold"> Inhale : Exhale Ratio</div>
                            <div className="col-12 col-md-9 mt-2 ">{content?.data?.breathing_pattern?.inhale_exhale_ratio|| '-' }</div>

                        </div>
                    </div>
                    <hr/>
                    <div className='mt-4'>
                        <div className='fw-semibold text-secondary text-uppercase'>Mental & Emotional Well-being: </div>
                        <div className="text-muted mb-3">How do you approach and manage recurring patterns or triggers?</div>
                        <div>
                        {
                            YogaArrivalConsultationField?.mentalEmotionalWellbeing?.map((item, index)=>(
                                <React.Fragment key={index}>
                                    <div className='mb-2 row' key={index}>
                                        <div className="col-9 col-md-4 mt-1  fw-semi-bold">{item.label}</div>
                                        <div className="col-3 col-md-8 mt-2 ">{content?.data?.mental_emotional_wellbeing?.[item.key] || '-'}</div>
                                    </div>
                                </React.Fragment>
                                ))
                        }
                        </div>
                        <div className='mb-2 row'>
                            <div className="col-12 col-md-4 mt-2  fw-semi-bold"> Any other points to share</div>
                            <div className="col-12 col-md-8 mt-2 ">{content?.data?.mental_emotional_wellbeing?.other_points|| '-' }</div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center py-2 fw-semibold  text-brown border-bottom">
                SUMMARY ASSESSMENT AND TREATMENT PLAN
                </div>
                <div className='m-3'>
                    <div>
                    <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>
                    {
                            content?.data?.summary_assessment_treatment_plan?.objectives?.map((item, index)=>{
                                return (
                                    <div className='border mb-4' key={index}>
                                        <div className='fw-semibold text-secondary p-2 bg-light text-uppercase'> 
                                            <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(index+1)?.toString()?.padStart(2, 0)}

                                        </div>
                                        <div className='w-100'>
                                        <div>
                                            {
                                                YogaArrivalConsultationField?.objectives?.map((item, subIndex)=>(
                                                    <div className='row m-0' key={subIndex}>
                                                        <div className="fw-semi-bold border p-2 mr-2 col-12 col-md-4 col-lg-3">{item?.label}</div>
                                                        <div className="text-break border p-2 white-space col-12 col-md-8 col-lg-9">{content.data?.summary_assessment_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</div>
                                                    </div>
                                                    ))
                                            }
                                        </div>
                                    </div>
                                    </div>
                                )
                            })
                        }
                        <div className='ms-3'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
