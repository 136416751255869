import { call, put, takeEvery } from "redux-saga/effects";
import { LOGIN_VERIFY_OTP, LOGIN_FAILURE, OTP_REQUEST_START, OTP_REQUEST_END, SET_VERIFY_SUCCESS, SET_USER_DATA} from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../../axios/api";

export const loginVerifyOTPReq = async({data})=>{
    const BODY = data.otpFor === "email" ?
        {
            "otp_for": "email",
            "email_id": data.email,
            "OTP": data.otp,
        }:
        {
            "otp_for": "phone",
            "phone_no": data.phone,
            "OTP": data.otp,
        }


    return axiosInstance.post(apiEndpoints.VERIFY_OTP_API, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400 || errors.response.data.statusCode === 401) {
                notify("error", "Invalid OTP");
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* loginVerifyOTP({data}){
    yield put({ type: OTP_REQUEST_START })
    try{
        let result = yield call(loginVerifyOTPReq, {data})
        if(result.statusCode === 200){
            yield put({ type: OTP_REQUEST_END });
            data?.onSuccessCallback?.()
            notify('success', result.message)
    }
    }catch(error){
        yield put({ type: LOGIN_FAILURE, message: error.message });
    }
}


function* loginVerifyOTPSaga(){
    yield takeEvery(LOGIN_VERIFY_OTP, loginVerifyOTP)
}

export default loginVerifyOTPSaga;