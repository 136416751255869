import { combineReducers } from 'redux'
import loginReducer from './AuthReducers/LoginReducer'
import forgotPasswordReducer from './AuthReducers/ForgotPasswordReducer'
import logoutReducer from '../reducers/AuthReducers/LogoutReducer'
import {
    guestListReducer,
    guestNameListReducer,
    searchedGuestResult
} from '../reducers/GuestListReducers/GuestListReducer'
import {
    prelimAssessmentReducer,
    wellbeingReportReducer,
    preparatoryDietReducer,
    consultationNotesReducer,
    sessionReducer,
    guestDetailsReducer,
    testimonialReducer,
    programmeFeedbackReducer
} from '../reducers/GuestDataReducers/GuestDataReducer'

import {queryListReducer} from '../reducers/QueryReducrs/QueryReducr'
import {groupClassesAndActivityReducer} from '../reducers/GroupClassesAndActivityReducers/GroupClassesAndActivityReducer'
import {statusReportReducer} from '../reducers/StatusReportReducers/StatusReportReducer'
import { programReducer } from './ProgramReducers/ProgramReducer'

const rootReducer = combineReducers ({
    loginReducer,
    guestListReducer,
    guestNameListReducer,
    searchedGuestResult,
    logoutReducer,
    prelimAssessmentReducer,
    wellbeingReportReducer,
    preparatoryDietReducer,
    consultationNotesReducer,
    sessionReducer,
    guestDetailsReducer,
    queryListReducer,
    groupClassesAndActivityReducer,
    statusReportReducer,
    testimonialReducer,
    programmeFeedbackReducer,
    programReducer,
    forgotPasswordReducer
})

export default rootReducer;