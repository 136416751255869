import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form} from 'formik'
import FormLayout from '../../components/From Layout/FormLayout'
import InputBox from '../../components/Form Components/InputBox/InputBox'
import emailIcon from '../../assets/images/email.svg'
import Button from '../../components/Form Components/Button'
import { emailValidation } from '../../validationSchema/emailMobileSchema'
import { login } from '../../redux/actions/AuthActions/LoginAction'
import "./login.scss"
import * as routes from '../../routes/route'
import { formTypes } from '../../const/constants'


function EmailLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="login-form">
      <FormLayout title="Enter Your Registered Email">
      
        <div className="muted-text">
          Please enter your registered email address, We will sent you an OTP for verification.
        </div>

        <Formik
          initialValues={{ email: ''}}
          validationSchema={emailValidation}
          onSubmit={async(values,  { resetForm })=>{
            resetForm()
            dispatch(login({
              "otp_for": "email",
              "email_id": values.email,
              "type": formTypes.LOGIN,
              navigate
            }))                         
          }}
        >
          {
            (formik)=>{
              return (
                <Form name="login">
                  <div className="input-container">
                    <InputBox type="email" name="email" placeholder="Email Address" icon={emailIcon}/>
                  </div>

                  <div className="navigation">
                    <div className="text-end">
                      {/* <Link end="true" to={routes.LOGIN_WITH_MOBILE_ENDPOINT}>Login via Mobile Number</Link> */}
                    </div>
                    <div className="text-center">
                      <div className='d-flex justify-content-center mt-4'>
                        <Button content="continue" type="submit" arrow="right" disabled={!(formik.dirty && formik.isValid)}/>
                      </div>
                      <div className='mt-3'>
                        <span>Forgot Password? </span>
                        <Link end="true" to={routes.FORGOT_PASSWORD_ENDPOINT}>Click here</Link>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </FormLayout>
    </div>
  )
}

export default EmailLogin