import * as type from '../../constants';

const initialState = {
  forgotPasswordData: null,
  loading: false,
  error: null,
}

export default function forgotPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case type.FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
      }
    case type.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPasswordData: action.payload,
        error: null
      }

    case type.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
        forgotPasswordData: null
      }
    

    default:
        return state
    }
}