import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form} from 'formik'
import FormLayout from '../../components/From Layout/FormLayout'
import InputBox from '../../components/Form Components/InputBox/InputBox'
import lockIcon from '../../assets/images/lock.svg'
import Button from '../../components/Form Components/Button'
import { emailValidation } from '../../validationSchema/emailMobileSchema'
import { login } from '../../redux/actions/AuthActions/LoginAction'
import "./EnterPassword.scss"
import * as routes from '../../routes/route'
import {ReactComponent as Arrow} from "../../assets/images/arrow-left.svg"
import { enterPassword } from '../../redux/actions/AuthActions/PasswordAction'


function EnterPassword() {
  
  const {loginData} = useSelector(state=> state.loginReducer)
  const {forgotPasswordData} = useSelector(state=> state.forgotPasswordReducer)
  let data =  forgotPasswordData || loginData;
  const isLogin = localStorage.getItem("UDID")

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(()=>{
    if(isLogin)
      navigate(-1)
    else if(!data)
        navigate(routes.LOGIN_WITH_EMAIL_ENDPOINT)
  }, [])
  

  //on direct accessing the url 
  if(!data || isLogin)
    return null;

  return (
    <div className="create-password-form">
      <FormLayout title="Enter Your Password">
        <Formik
          initialValues={{ password: ''}}
          onSubmit={async(values,  { resetForm })=>{
            resetForm()
            dispatch(enterPassword({
              ...values,
              ...data,
              onSuccessCallback: ()=>{navigate("/")},
            }))                         
          }}
        >
          {
            (formik)=>{
              return (
                <Form name="login">
                  <div className="input-container mt-3">
                    <InputBox type="password" name="password" placeholder="Enter Password" iconwidth={'19px'} icon={lockIcon}/>
                  </div>

                  <div className="navigation">
                    <div className="text-end">
                      {/* <Link end="true" to={routes.LOGIN_WITH_MOBILE_ENDPOINT}>Login via Mobile Number</Link> */}
                    </div>
                    <div className="text-center">
                      <div className='d-flex align-items-center justify-content-between mt-1'>
                        <div className='text-primary fw-semi-bold d-flex align-items-center cursor-pointer' onClick={()=>navigate(-1)}><Arrow fill='#0d6efd' width={'13px'}/> &nbsp; Back</div>
                        <Button content="verify" type="submit" disabled={!(formik.dirty && formik.isValid)}/>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </FormLayout>
    </div>
  )
}

export default EnterPassword