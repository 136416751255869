import { call, put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../../axios/axiosInstance";
import { notify } from "../../../utils/notification";
import { GUEST_DETAILS, GUEST_DETAILS_FAILURE, GUEST_DETAILS_SUCCESS, UPDATE_GUEST_DOB, UPDATE_GUEST_DOB_FALIURE, UPDATE_GUEST_DOB_SUCCESS } from "../../constants";
import {logOut} from '../../../utils/helpers/logout'
import apiEndpoints from "../../../axios/api";
import moment from "moment";

export const getGuestDetailsReq = async({data})=>{   
    return axiosInstance.get(`${apiEndpoints.GET_GUEST_DETAILS_API}?user_id=${data.guestId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getGuestDetails({data}){
    try{
        let result = yield call(getGuestDetailsReq, {data})
        if(result.statusCode === 200){
            yield put({ type: GUEST_DETAILS_SUCCESS, payload: result.data});  
        }  
    }catch(error){
        yield put({ type: GUEST_DETAILS_FAILURE, message: error.message });
    }
}


export const updateGuestDOBReq = async({data})=>{
    const BODY = {
        "user_id": data.user_id,
        "dob": data.DOB,
      }

    return axiosInstance.put(apiEndpoints.UPDATE_GUEST_DOB_API, { data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updateGuestDOB({data}){
    try{
        let result = yield call(updateGuestDOBReq, {data})

        if(result.statusCode === 200){
            data?.onSuccessCallback?.()
            notify('success', result.message);
        }    
        yield put({ type: UPDATE_GUEST_DOB_SUCCESS, payload: {DOB: moment(data.DOB).format('MM/DD/YYYY')}});

    }catch(error){
        yield put({ type: UPDATE_GUEST_DOB_FALIURE }); 
    }
}

function* guestDetailsSaga(){
    yield takeLatest(GUEST_DETAILS, getGuestDetails)
    yield takeLatest(UPDATE_GUEST_DOB, updateGuestDOB)
}

export default guestDetailsSaga;