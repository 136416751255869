import { useEffect, useState } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditYogaDepartureConsultation.scss'
import { ADD, YogaArrivalConsultationJSON, YogaDepartureConsultationField } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import { getConsultationNotes } from '../../../../../redux/actions/GuestDataActions/ConsultationNotesActions'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, getDateObjectFromTimeString, parseJSON } from '../../../../../utils/helpers/universalFunctions'
import { TimeSelector } from '../../../../../components/TimeSelector/TimeSelector'
import { CircularProgress } from '@mui/material'
import RadioButton from '../../../../../components/RadioButton/RadioButton'
import targetIcon from '../../../../../assets/images/target.svg'
import Select from "react-select";
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'

const Objectives = ({setKey, removeObjective, content, handleAddMore})=>{
    return (
        <div className="border w-100 rounded mt-5 summary">
            <div className="heading py-3">
            PROGRAMME & TREATMENT PLAN
            </div>
            <div className='p-2 p-md-3'>
                <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.programme_and_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>
                <div className="d-md-flex flex-wrap align-items-stretch justify-content-start">
                    {
                        content?.data?.programme_and_treatment_plan?.objectives?.map((subitem, subindex)=> {
                            return (
                                <div className="objective-box p-md-2 mb-3" key={subindex}>
                                    <div className='border rounded'>
                                        <div className="heading p-3 py-2 d-flex justify-content-between align-items-center">
                                        <div>
                                            <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(subindex+1)?.toString()?.padStart(2, 0)}
                                        </div>                                            
                                            <div className='remove-btn' onClick={()=>removeObjective(subindex)}>
                                                <RemoveBtn/>
                                            </div>
                                        </div>
                                        <div className="p-2 p-md-3">
                                        {
                                            YogaDepartureConsultationField?.objectives?.map((item, index)=>{
                                                return (
                                                    <div className="data-row d-flex" key={index}>
                                                        <div className="fw-semi-bold mt-2" style={{width: '220px'}}>{item.label}</div>
                                                        <div className='data-input'>
                                                            <TextArea
                                                                placeholder={"Enter "}
                                                                onChange={value =>setKey(["programme_and_treatment_plan", "objectives", subindex, item.key], value)}
                                                                row={2}
                                                                value={content?.data?.programme_and_treatment_plan?.objectives?.[subindex]?.[item.key] || ''}/>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    
                    <div className="add-more-container"  onClick={handleAddMore}>
                        <div className="add-more ">
                            Add More Objectives
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export const AddEditYogaDepartureConsultation = ({content, setContent, conductedBy, formType,  guestId, userProgramId, guestName, uploadComponent})=>{
    const dispatch = useDispatch()
      const {consultationNotesData, programmeName, followUpTimeline, loading} = useSelector(state=> state.consultationNotesReducer)
      const programList = useSelector(state=> state.programReducer.programList)

  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.programme_and_treatment_plan.objectives.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.programme_and_treatment_plan.objectives = [...updatedData.data.programme_and_treatment_plan.objectives, YogaArrivalConsultationJSON.data.summary_assessment_treatment_plan.objectives[0]]
            return updatedData;
        })
    }


    useEffect(()=>{
        if(guestId && userProgramId && formType===ADD){
            dispatch(getConsultationNotes({guestId, userProgramId, noteType: "Yoga Arrival Consultation"}))
        }
      },[])

    useEffect(()=>{
        let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail);
        if(formType===ADD){ //have dummy JSON
        setContent(prev=>{
            let newData= {...prev};
            newData.date = moment().format("YYYY-MM-DD")
            if(notes_detail){
                newData.data.programme_and_treatment_plan = {
                    "dosha_prakriti": notes_detail?.data?.summary_assessment_treatment_plan?.dosha_prakriti,
                    "dosha_vikruiti": notes_detail?.data?.summary_assessment_treatment_plan?.dosha_vikruiti,
                    "objectives": notes_detail?.data?.summary_assessment_treatment_plan?.objectives
                }
    
                for (let key in notes_detail.data.yogic_assessment) {
                    newData.data.health_assessment[key].arrival_reading = capitalize(notes_detail?.data.yogic_assessment[key] || "")
                    newData.data.health_assessment[key].value = capitalize(notes_detail?.data.yogic_assessment[key] || "")
                }
    
                for (let key in notes_detail.data.others) {
                    newData.data.health_assessment[key].arrival_reading = capitalize(notes_detail?.data.others[key] || "")
                    newData.data.health_assessment[key].value = capitalize(notes_detail?.data.others[key] || "")
                }
    
                newData.data.health_assessment.breath_inhale_exhale_ratio.arrival_reading = notes_detail?.data?.breathing_pattern?.inhale_exhale_ratio
                newData.data.health_assessment.breath_inhale_exhale_ratio.value = notes_detail?.data?.breathing_pattern?.inhale_exhale_ratio
            }
            return newData;
        })
        }
    },[consultationNotesData])

    return ( 
      <div className='yoga-departure-consultation-form'>
        {
          loading ?
          <div className="loader mx-auto">
            <CircularProgress sx={{'color': '#74613C'}} size={20}/>
          </div>
          :
          <>
            <>
            <div className="d-md-flex py-4 mb-2 border-top rounded justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3 mb-2 mb-md-0">Conducted By: </div>
                    {/* <TextField
                        width={'300px'}
                        type={'text'}
                        placeholder='Enter name' 
                        value={content?.conducted_by || ''} 
                        onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                    /> */}
                    {conductedBy || '-'}
                </div>
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3">Date</div>
                    <DatePicker
                    value={content.date ? new Date(content.date) : ''}
                    onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                    width={'300px'}
                    />
                </div>
                </div>
                <div className=" w-100 rounded mb-4">
                    <div className="fw-semi-bold">
                        Dear {guestName},
                    </div>
                    Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s Yoga Consultant during post-stay online follow-ups.  
                    </div>
                    <Objectives
                        content={content}
                        removeObjective={removeObjective}
                        setKey={setKey}
                        handleAddMore={handleAddMore}
                    />

                <div className="border w-100 mt-5 rounded healthassessment">
                    <div className="heading py-3">
                    HEALTH ASSESSMENT
                    </div>
                    <div className='p-2 p-md-3'>
                        <div className="overflow-x-auto pb-4">
                            <div style={{minWidth: '600px'}}>
                                <div className="row fw-semibold pb-2 border-bottom w-100 m-0 mb-3" >
                                    <div className="col-3">Parameter</div>
                                    <div className='col-4'>
                                        On Arrival
                                    </div>
                                    <div className='col-5'>
                                        On Departure
                                    </div>
                                </div>
                                    {
                                        YogaDepartureConsultationField?.healthAssessment?.map((item, index)=>{
                                            return (
                                                <div className="row w-100 mb-3 m-0" key={index}>
                                                    <div className="title mt-2 col-3">{item.label}</div>
                                                    <div className='col-4 mt-2'>
                                                        {content?.data?.health_assessment?.[item.key]?.arrival_reading || '-'}
                                                    </div>
                                                    <div className='col-5'>
                                                        {
                                                            item.radio ?
                                                            <div>
                                                                <div className='row'>
                                                                    <div className="col-12 col-xl-4 text-center mt-1 mt-xl-3">
                                                                        <RadioButton
                                                                            name={item.key}
                                                                            label={"Beginner"}
                                                                            value={"Beginner"}
                                                                            checked={content?.data?.health_assessment?.[item.key]?.value==="Beginner"}
                                                                            onChange={(e)=>{setKey(["health_assessment", item.key, "value"], e.target.value)}}
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 col-xl-4 text-center mt-1 mt-xl-3">
                                                                        <RadioButton
                                                                            name={item.key}
                                                                            label={"Intermmediate"}
                                                                            value={"Intermediate"}
                                                                            checked={content?.data?.health_assessment?.[item.key]?.value==="Intermediate"}
                                                                            onChange={(e)=>{setKey(["health_assessment", item.key, "value"], e.target.value)}}
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 col-xl-4 text-center mt-1 mt-xl-3">
                                                                        <RadioButton
                                                                            name={item.key}
                                                                            label={"Advanced"}
                                                                            value={"Advanced"}
                                                                            checked={content?.data?.health_assessment?.[item.key]?.value==="Advanced"}
                                                                            onChange={(e)=>{setKey(["health_assessment", item.key, "value"], e.target.value)}}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="data-input h-100">
                                                                <TextArea
                                                                    row={3}
                                                                    fullHeight={true}
                                                                    placeholder={"Remarks "}
                                                                    onChange={value =>setKey(["health_assessment", item.key, "value"], value)}
                                                                    value={content?.data?.health_assessment?.[item.key]?.value || ''}/>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                    </div>
                </div>
                <div className="border w-100 mt-5 rounded systematic ">
                    <div className="heading py-3">
                        RECOMMENDATIONS AND FOLLOW UPS
                    </div>
                    <div className='overflow-x-scroll'>
                        <div className='p-3' style={{minWidth: '900px'}} >
                            <div className="row fw-semibold pb-2 border-bottom mb-3" >
                                <div className="mt-2 px-1 text-break col-2">Daily Routine</div>
                                <div className="mt-2 px-1 text-break col-3">Details</div>
                                <div className="mt-2 px-1 text-break col-3">Time of Practice</div>
                                <div className='px-1 text-break col-2'>
                                Duration/Repetition
                                </div>
                                <div className='px-1 text-break col-2'>
                                    Frequency
                                </div>
                            </div>
                                {
                                    YogaDepartureConsultationField?.recommendationsAndFollowUps?.map((item, index)=>{
                                        return (
                                            <div className="row w-100 mb-2" key={index}>
                                                <div className="title mt-2 px-1 col-2">{item.label}</div>
                                                <div className='px-1 col-3'>
                                                    <div className="data-input">
                                                        <TextArea
                                                            row={2}
                                                            placeholder={"Enter "}
                                                            onChange={value =>setKey(["recommendations_and_follow_ups", item.key, "details"], value)}
                                                            value={content?.data?.recommendations_and_follow_ups?.[item.key]?.details || ''}/>
                                                    </div>
                                                </div>
                                                <div className='px-1 col-3 pt-2'>
                                                    <TimeSelector
                                                        width={'100%'}
                                                        ampm={true}
                                                        time={getDateObjectFromTimeString(content?.data?.recommendations_and_follow_ups?.[item.key]?.time) || ''}
                                                        setTime={(value)=>setKey(["recommendations_and_follow_ups", item.key, "time"], moment(new Date(value)).format('HH:mm A')) }
                                                        
                                                    />
                                                </div>
                                                <div className='px-1 col-2'>
                                                    <div className="data-input">
                                                        <TextArea
                                                            row={2}
                                                            placeholder={"Enter "}
                                                            onChange={value =>setKey(["recommendations_and_follow_ups", item.key, "duration"], value)}
                                                            value={content?.data?.recommendations_and_follow_ups?.[item.key]?.duration || ''}/>
                                                    </div>
                                                </div>
                                                <div className='px-1 col-2'>
                                                    <div className="data-input">
                                                        <TextArea
                                                            row={2}
                                                            placeholder={"Enter "}
                                                            onChange={value =>setKey(["recommendations_and_follow_ups", item.key, "frequency"], value)}
                                                            value={content?.data?.recommendations_and_follow_ups?.[item.key]?.frequency || ''}/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                        </div>
                    </div>
                </div>

                <div className="border w-100 mt-5 mb-3 rounded current-dietary-pattern ">
                    <div className="heading py-3">
                    FOLLOW UP PROGRAMME
                    </div>
                    <div className='p-2 p-md-3'>
                        <div className='d-md-flex mb-2'>
                            <div className="fw-semi-bold mb-1 flex-shrink-0" style={{width: '230px'}}>Follow up Consultation:</div>
                            <div className="text-break flex-grow-1 white-space">
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["follow_up_programme", "consultation"], value)}
                                    value={content?.data?.follow_up_programme?.consultation || ''}/>
                            </div>
                        </div>
                        <div className='d-md-flex mb-2'>
                            <div className="fw-semi-bold mb-1 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Programme):</div>
                            <div className="text-break flex-grow-1 white-space">
                                <Select
                                    className="name-select"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={
                                        programList ? programList?.map(item=> ({
                                          label: item.title,
                                          value: item.title
                                        }))
                                        : []
                                    }
                                    placeholder='Select'
                                    value={
                                        content?.data?.follow_up_programme?.programme ?
                                        {
                                            label: content?.data?.follow_up_programme?.programme,
                                            value: content?.data?.follow_up_programme?.programme
                                        }
                                        :
                                        null
                                    }
                                    onChange={e =>setKey(["follow_up_programme", "programme"], e?.value || '')}
                                />
                            </div>
                        </div>
                        <div className='d-md-flex mb-2'>
                            <div className="fw-semi-bold mb-1 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Timeline):</div>
                            <div className="text-break flex-grow-1 white-space">
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["follow_up_programme", "timeline"], value)}
                                    value={content?.data?.follow_up_programme?.timeline || ''}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <div className="w-50">
                {uploadComponent}
            </div>
          </>
        }
      </div>
    )
}