import moment from 'moment'
import { FitnessDepartureConsultationField, YogaDepartureConsultationField } from '../NoteFormsConstants'
import { useSelector } from 'react-redux'
import targetIcon from '../../../../assets/images/target.svg'
import { Fragment } from 'react'


export const ViewFitnessDepartureConsultation = ({content, guestName, conductedBy})=>{

    return (
        <div className="my-3 ">
            <div className="rounded border d-sm-flex d justify-content-between bg-light p-2 mb-2">
                <div className='d-flex mb-2 mb-sm-0'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Conducted By:</div>
                    <div className="text-break white-space">{conductedBy || '-'}</div>
                </div>
                <div className='d-flex  '>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>
            <div className=" w-100 rounded my-4">
                <div className="fw-semi-bold">
                    Dear {guestName},
                </div>
                Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s Yoga Consultant during post-stay online follow-ups.    
                </div>
            <div className='border rounded overflow-hidden'>
                <div className="bg-light text-center  border-bottom text-brown py-2 fw-semibold">
                PROGRAMME & TREATMENT PLAN
                </div>
                <div className='p-2 p-md-3'>
                <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>
                    {
                        content?.data?.programme_and_treatment_plan?.objectives?.map((item, index)=>{
                                return (
                                    <div className={index===0 ? '': ' mt-4 '} key={index}>
                                        <div className='border bg-light fw-semibold text-secondary p-2 text-uppercase'>                                             
                                            <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(index+1)?.toString()?.padStart(2, 0)}
                                        </div>                                        
                                        <table className='p-2 w-100'>
                                            <div>
                                            {
                                                FitnessDepartureConsultationField?.objectives?.map((item, subIndex)=>(
                                                    <div className='row m-0' key={subIndex}>
                                                        <div className="fw-semi-bold border p-2 mr-2 col-12 col-md-4 col-lg-3">{item?.label}</div>
                                                        <div className="text-break border col-12 col-md-8 col-lg-9 p-2 white-space">{content.data?.programme_and_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</div>
                                                    </div>
                                                    ))
                                            }
                                            </div>
                                        </table>
                                    </div>
                                )
                            })
                        }
                        
                </div>
            </div>
            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center text-uppercase  border-bottom text-brown py-2 fw-semibold">
                Cardio (Treadmill) test
                </div>
                <div className="overflow-x-auto">
                    <div  style={{minWidth: '1000px'}}>
                        <div className="row text-center m-0 fw-semibold" >
                            <div className="p-2 border col-2"></div>
                            <div className='border col-5 p-0'>
                                <div className="py-2 border">On Arrival</div>
                                <div className="row m-0 fw-semibold" >
                                    <div className="p-2 border border-bottom-0 col-3">Speed</div>
                                    <div className='p-2 border border-bottom-0 col-3'>Incline</div>
                                    <div className='p-2 border border-bottom-0 col-3'>Duration</div>
                                    <div className='p-2 border border-bottom-0 col-3'>HR</div>
                                </div>

                            </div>
                            <div className='border col-5 p-0'>
                                <div className="py-2 border">On Departure</div>
                                <div className="row m-0 fw-semibold" >
                                    <div className="p-2 border border-bottom-0 col-3">Speed</div>
                                    <div className='p-2 border border-bottom-0 col-3'>Incline</div>
                                    <div className='p-2 border border-bottom-0 col-3'>Duration</div>
                                    <div className='p-2 border border-bottom-0 col-3'>HR</div>
                                </div>

                            </div>
                        </div>
                            {
                                FitnessDepartureConsultationField?.cardioTest?.map((item, index)=>{
                                    return (
                                        <div className="row m-0" key={index}>
                                            <div className=" border border-bottom-0 p-2 col-2 fw-semi-bold text-secondary ">{item.label}</div>
                                            <div className='border border-bottom-0 p-0 col-5'>
                                                <div className="row m-0" >
                                                    <div className="p-2 border border-bottom-0 col-3">{content?.data?.cardio_test?.[item.key]?.on_arrival?.speed ? `${content?.data?.cardio_test?.[item.key]?.on_arrival?.speed} KMPH`: '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-3'>{content?.data?.cardio_test?.[item.key]?.on_arrival?.incline ? `${content?.data?.cardio_test?.[item.key]?.on_arrival?.incline} %`: '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-3'>{content?.data?.cardio_test?.[item.key]?.on_arrival?.duration ? `${content?.data?.cardio_test?.[item.key]?.on_arrival?.duration} Min`: '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-3'>{content?.data?.cardio_test?.[item.key]?.on_arrival?.hr ? `${content?.data?.cardio_test?.[item.key]?.on_arrival?.hr} bps`: '-'}</div>
                                                </div>
                                            </div>
                                            <div className='border border-bottom-0 p-0 col-5'>
                                                <div className="row m-0" >
                                                    <div className="p-2 border border-bottom-0 col-3">{content?.data?.cardio_test?.[item.key]?.on_departure?.speed ? `${content?.data?.cardio_test?.[item.key]?.on_departure?.speed} KMPH`: '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-3'>{content?.data?.cardio_test?.[item.key]?.on_departure?.incline ? `${content?.data?.cardio_test?.[item.key]?.on_departure?.incline} %`: '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-3'>{content?.data?.cardio_test?.[item.key]?.on_departure?.duration ? `${content?.data?.cardio_test?.[item.key]?.on_departure?.duration} Min`: '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-3'>{content?.data?.cardio_test?.[item.key]?.on_departure?.hr ? `${content?.data?.cardio_test?.[item.key]?.on_departure?.hr} bps`: '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
            </div>
            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center text-uppercase  border-bottom text-brown py-2 fw-semibold">
                Strength
                </div>
                <div className="overflow-x-auto">
                    <div  style={{minWidth: '1000px'}}>
                        <div className="row text-center m-0 fw-semibold" >
                            <div className="p-2 border col-2"></div>
                            <div className='border col-5 p-0'>
                                <div className="py-2 border">On Arrival</div>
                                <div className="row m-0 fw-semibold" >
                                    <div className="p-2 border border-bottom-0 col-6">Weight</div>
                                    <div className='p-2 border border-bottom-0 col-6'>Reps</div>
                                </div>

                            </div>
                            <div className='border col-5 p-0'>
                                <div className="py-2 border">On Departure</div>
                                <div className="row m-0 fw-semibold" >
                                    <div className="p-2 border border-bottom-0 col-6">Weight</div>
                                    <div className='p-2 border border-bottom-0 col-6'>Reps</div>
                                </div>

                            </div>
                        </div>
                            {
                                FitnessDepartureConsultationField?.strength?.map((item, index)=>{
                                    return (
                                        <div className="row m-0" key={index}>
                                            <div className=" border border-bottom-0 p-2 col-2 fw-semi-bold text-secondary ">{item.label}</div>
                                            <div className='border border-bottom-0 p-0 col-5'>
                                                <div className="row m-0" >
                                                    <div className="p-2 border border-bottom-0 col-6">{content?.data?.strength?.[item.key]?.on_arrival?.weight || '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-6'>{content?.data?.strength?.[item.key]?.on_arrival?.reps || '-'}</div>
                                                </div>
                                            </div>
                                            <div className='border border-bottom-0 p-0 col-5'>
                                                <div className="row m-0" >
                                                    <div className="p-2 border border-bottom-0 col-6">{content?.data?.strength?.[item.key]?.on_departure?.weight || '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-6'>{content?.data?.strength?.[item.key]?.on_departure?.reps || '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
            </div>
            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center text-uppercase  border-bottom text-brown py-2 fw-semibold">
                Muscular Endurance
                </div>
                <div className="overflow-x-auto">
                    <div  style={{minWidth: '1000px'}}>
                        <div className="row text-center m-0 fw-semibold" >
                            <div className="p-2 border col-2"></div>
                            <div className='border col-5 p-0'>
                                <div className="py-2 border">On Arrival</div>
                                <div className="row m-0 fw-semibold" >
                                    <div className="p-2 border border-bottom-0 col-6">Unit</div>
                                    <div className='p-2 border border-bottom-0 col-6'>Measurement</div>
                                </div>

                            </div>
                            <div className='border col-5 p-0'>
                                <div className="py-2 border">On Departure</div>
                                <div className="row m-0 fw-semibold" >
                                    <div className="p-2 border border-bottom-0 col-6">Unit</div>
                                    <div className='p-2 border border-bottom-0 col-6'>Measurement</div>
                                </div>

                            </div>
                        </div>
                            {
                                FitnessDepartureConsultationField?.muscularEndurance?.map((item, index)=>{
                                    return (
                                        <div className="row m-0" key={index}>
                                            <div className=" border border-bottom-0 p-2 col-2 fw-semi-bold text-secondary ">{item.label}</div>
                                            <div className='border border-bottom-0 p-0 col-5'>
                                                <div className="row m-0" >
                                                    <div className="p-2 border border-bottom-0 col-6">{content?.data?.muscular_endurance?.[item.key]?.on_arrival?.units || '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-6'>{content?.data?.muscular_endurance?.[item.key]?.on_arrival?.measurement || '-'}</div>
                                                </div>
                                            </div>
                                            <div className='border border-bottom-0 p-0 col-5'>
                                                <div className="row m-0" >
                                                    <div className="p-2 border border-bottom-0 col-6">{content?.data?.muscular_endurance?.[item.key]?.on_departure?.units || '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-6'>{content?.data?.muscular_endurance?.[item.key]?.on_departure?.measurement || '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
            </div>
            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center text-uppercase  border-bottom text-brown py-2 fw-semibold">
                Flexibility
                </div>
                <div className="overflow-x-auto">
                    <div  style={{minWidth: '1000px'}}>
                        <div className="row text-center m-0 fw-semibold" >
                            <div className="p-2 border col-2"></div>
                            <div className='border col-5 p-0'>
                                <div className="py-2 border">On Arrival</div>
                                <div className="row m-0 fw-semibold" >
                                    <div className="p-2 border border-bottom-0 col-6">Right Side</div>
                                    <div className='p-2 border border-bottom-0 col-6'>Left Side</div>
                                </div>

                            </div>
                            <div className='border col-5 p-0'>
                                <div className="py-2 border">On Departure</div>
                                <div className="row m-0 fw-semibold" >
                                    <div className="p-2 border border-bottom-0 col-6">Right Side</div>
                                    <div className='p-2 border border-bottom-0 col-6'>Left Side</div>
                                </div>

                            </div>
                        </div>
                            {
                                FitnessDepartureConsultationField?.flexibility?.map((item, index)=>{
                                    return (
                                        <div className="row m-0" key={index}>
                                            <div className=" border border-bottom-0 p-2 col-2 fw-semi-bold text-secondary ">{item.label}</div>
                                            <div className='border border-bottom-0 p-0 col-5'>
                                                <div className="row m-0" >
                                                    <div className="p-2 border border-bottom-0 col-6">{content?.data?.flexibility?.[item.key]?.on_arrival?.right_side || '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-6'>{content?.data?.flexibility?.[item.key]?.on_arrival?.left_side || '-'}</div>
                                                </div>
                                            </div>
                                            <div className='border border-bottom-0 p-0 col-5'>
                                                <div className="row m-0" >
                                                    <div className="p-2 border border-bottom-0 col-6">{content?.data?.flexibility?.[item.key]?.on_departure?.right_side || '-'}</div>
                                                    <div className='p-2 border border-bottom-0 col-6'>{content?.data?.flexibility?.[item.key]?.on_departure?.left_side || '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
            </div>
            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center  border-bottom text-brown py-2 fw-semibold">
                RECOMMENDATIONS AND FOLLOW UPS
                </div>
                <div className="overflow-x-auto">
                    <div  style={{minWidth: '800px'}}>
                        <div className="row m-0 fw-semibold" >
                        <div className="p-2 border col-2">Daily Routine</div>
                        <div className="p-2 border col-4">Details</div>
                            <div className='p-2 border col-2'>
                            Time of practice
                            </div>
                            <div className="p-2 border col-2">Duration/Repetition</div>
                            <div className='p-2 border col-2'>
                                Frequency
                            </div>
                        </div>
                        {
                        FitnessDepartureConsultationField?.recommendationsAndFollowUps?.map((item, index)=>{
                            return (
                                <Fragment key={index}>
                                    {
                                        item?.isArray ?
                                        <div className='title border m-0 row'>
                                            <div className="col-12 p-2 text-uppercase text-muted fw-semi-bold">
                                                {item.label}
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                <div>
                                    {
                                        content?.data?.recommendations_and_follow_ups?.[item.key]?.map((subItem, subindex)=>{
                                            return (
                                            <div key={subindex}>
                                                <div className="row m-0 w-100" >
                                                    <div className="title border text-uppercase border-bottom-0 text-muted fw-semi-bold p-2 col-2"> <span className={`${item.isArray ? 'ps-2' : ''}`}>{item.isArray ? (subindex+1)?.toString()?.padStart(2, '0') : item.label}</span> </div>
                                                    <div className='border border-bottom-0  col-4 p-2'>
                                                        <div className="data-input">
                                                        {content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.details || '-'}
                                                        </div>
                                                    </div>
                                                    <div className='border border-bottom-0  col-2 p-2 '>
                                                        {content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.time || '-'}
                                                    </div>
                                                    <div className='border border-bottom-0  col-2 p-2'>
                                                        <div className="data-input">
                                                        {content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.duration || '-'}
                                                        </div>
                                                    </div>
                                                    <div className='border border-bottom-0  col-2 p-2'>
                                                        <div className="data-input  d-flex align-items-center">
                                                        {content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.frequency || '-'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                            </div>
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                                </Fragment>
                            )
                        })
                    }
                        </div>
                    </div>
            </div>

            <div className="border rounded my-3 overflow-hidden">
                <div className="bg-light text-brown text-center py-2 fw-semibold">
                FOLLOW UP PROGRAMME
                </div>
                <div className=' '>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '30%'}}>Follow up Consultation:</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content?.data?.follow_up_programme?.consultation || '-'}</div>
                    </div>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '30%'}}>Follow up Visit (Programme):</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content?.data?.follow_up_programme?.programme || '-'}</div>
                    </div>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '30%'}}>Follow up Visit (Timeline):</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content?.data?.follow_up_programme?.timeline || '-'}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
