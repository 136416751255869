import React, { useEffect, useRef } from 'react'
import './UploadButton.scss'
import {ReactComponent as UploadBtn } from '../../assets/images/upload.svg'
import {ReactComponent as RemoveBtn } from '../../assets/images/delete.svg'
import {ReactComponent as FileIcon} from '../../assets/images/file.svg'
import { Link } from 'react-router-dom'
import { fileTypes } from '../../screens/Notes/Forms/NoteFormsConstants'
import { notify } from '../../utils/notification'


//files state should be
//  const [files, setFiles] = useState({newFiles: [], uploadedFiles:[], uploadedFilesURL: [], removedUploadedFilesIndex:[]})


function UploadButton({name, files, setFiles}) {
  const inputFile = useRef(null);

  const onFileChange=(e)=>{
    const filesArray = Array.from(e.target.files);
    for(let i=0; i<filesArray.length; i++){
      if(!fileTypes.includes(filesArray[i].type)){
        notify("warning", "Only .pdf, .png, .jpg, .jpeg files are allowed.")
        return;
      }
    }

    setFiles(prev=>{
      let newData = {...prev};
      const newFiles = filesArray.filter(file =>
          !newData.newFiles.some(selectedFile =>  selectedFile.name === file.name && selectedFile.type === file.type)
      );
      newData.newFiles = [...newData.newFiles, ...newFiles];
      return newData
    })
  }

  const removeFile=(index)=>{
    setFiles(prev=>{
      let newData = {...prev};
      newData.newFiles.splice(index, 1);
      return newData
    })
  }

  const handleRemoveUploadedFile=(index)=>{
    setFiles(prev=>{
      let newData = {...prev};
      let pathToRemove = prev.uploadedFiles[index];
      newData.removedUploadedFiles.push(pathToRemove);
      newData.uploadedFiles.splice(index, 1);
      newData.uploadedFilesURL.splice(index, 1);
      return newData
    })
  }

  useEffect(()=>{
    if(files.newFiles?.length===0)
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
  }, [files])

  return (
    <div className='d-flex flex-column w-100 upload-btn-container'>
      {
        files.uploadedFiles?.length>0 &&
        <div className='d-flex my-3'>
          <div className="fw-semi-bold mr-2 flex-shrink-0 me-3" >Uploaded Files:</div>
          <div className="old-doc flex-grow-1">
            {
                files.uploadedFiles?.map((item, index)=>{
                return(
                  <div className='d-flex justify-content-between'>
                    <Link target="_blank" className='d-block text-decoration-none mb-2 fw-semi-bold' to={files.uploadedFilesURL[index]} key={index}>
                      <FileIcon/> &nbsp;
                      {item?.split('-').slice(1).join('-')}
                    </Link>
                    
                    <div className='ps-1 remove-btn flex-shrink-0' onClick={()=>handleRemoveUploadedFile(index)}>
                      <RemoveBtn/>
                    </div>
                  </div>
                )
              })
            }
            
          </div>
        </div>
      }
      <div className="font-semibold">
        <input
        ref={inputFile}
          multiple="multiple"
          className="d-none"
          name={name}
          id={name}
          type="file"
          accept=".pdf,.jpg,.jpeg,.png"
          onChange={onFileChange}
        ></input>
        <label
          htmlFor={name}
          className="upload-multiple-files-btn mt-4 d-flex align-items-center upload-btn">
          Upload Files &nbsp;&nbsp;
          <UploadBtn/>
        </label>

      </div>
      {
        files.newFiles?.length>0 &&
        <div className='file-conatiner w-fit rounded-sm p-2 pb-0 my-3'>
          {
            files.newFiles?.map((item, index)=>{
              return (
              <div className="file-box shadow-sm bg-light mb-3 p-3" key={index}>
                <div className='file-name'><FileIcon/> &nbsp;{item.name}</div>
                <div className='remove-btn' onClick={()=>removeFile(index)}>
                  <RemoveBtn/>
                </div>
              </div>
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default UploadButton