import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FormLayout from '../../components/From Layout/FormLayout'
import Button from '../../components/Form Components/Button'
import VerficationCodeInput from '../../components/Form Components/VerficationCodeInput/VerficationCodeInput'
import { loginResendOTP, loginVerifyOTP } from '../../redux/actions/AuthActions/LoginAction'
import { updateResendOTP, updateVerifyOTP } from '../../redux/actions/AuthActions/UpdateAction'
import './Verification.scss'
import * as routes from '../../routes/route'
import { formTypes } from '../../const/constants'

function Verification() {
  const location = useLocation()
  const location_state = location.state;
  const {loginData} = useSelector(state=> state.loginReducer)
  const {forgotPasswordData} = useSelector(state=> state.forgotPasswordReducer)
  const isLogin = localStorage.getItem("UDID")
  let data = location_state.type===formTypes.FORGOT_PASSWORD ? forgotPasswordData : loginData;

  const [otp, setOTP] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const handleSubmit = (e)=>{
    const {otpFor, phone, email, } = data
    const onSuccessCallback = ()=>{
      if(location_state.type===formTypes.FORGOT_PASSWORD)
        navigate(routes.CREATE_PASSWORD_ENDPOINT, {state: {type: location_state.type}, replace: true})
      else
        navigate(data.is_password_set ? routes.ENTER_PASSWORD_ENDPOINT : routes.CREATE_PASSWORD_ENDPOINT, {state: {type: location_state.type}, replace: true})
    }
    e.preventDefault()
    if(otpFor==="email"){
      dispatch(loginVerifyOTP({otpFor, email, otp, type: data?.type, onSuccessCallback}))
    }else if(otpFor==="phone"){
      dispatch(loginVerifyOTP({otpFor, phone, otp, type: data?.type, onSuccessCallback}))
    }else{
      dispatch(updateVerifyOTP({otpFor:"update", email, phone, otp}))
    }
    setOTP('')
  }

  const resendOTP = ()=>{
    const {otpFor, phone, email} = data

    if(otpFor==="email"){
      dispatch(loginResendOTP({otp_for: "email", email_id: email}))
    }else if(otpFor==="phone"){
      dispatch(loginResendOTP({otp_for: "phone", phone_no: phone}))
    }else{
      dispatch(updateResendOTP({email, phone}))
    }
  }

  const changeButton = ()=>{
    if(location_state.type===formTypes.FORGOT_PASSWORD)
      navigate(routes.FORGOT_PASSWORD_ENDPOINT, {replace: true})
    else
      navigate(routes.LOGIN_WITH_EMAIL_ENDPOINT, {replace: true})
  }


  useEffect(()=>{
    //accessing page on refresh or through direct link
    if(isLogin)
      navigate(-1)
    else if(!data)
        navigate(routes.LOGIN_WITH_EMAIL_ENDPOINT)
  }, [])

    //accessing page on refresh or through direct link
    if(!data || isLogin)
    return null;
  
  return (
    <div id="verification-form">
      <FormLayout title="Verification Code">
      
      <div className="muted-text">
        {
          (data?.otpFor === "email" || data?.otpFor === "update") && 
          <> 
            Please enter verification code sent to your email address ({data?.email}) &nbsp;
            <a className='link-tag' href="#" onClick={changeButton}>change</a>
          </>}
        {
          data?.otpFor === "phone" && 
          <>
            Please enter verification code sent to your mobile number ({data?.phone}) &nbsp;
            <a className='link-tag' href="#" onClick={changeButton}>change</a>
          </>
        }
        </div>

        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <div className="mx-auto">
              <VerficationCodeInput otp={otp} setOTP={setOTP}/>
            </div>
          </div>

          <div className="navigation">
              <div className="d-flex justify-content-between align-items-center mt-3">
                <a href="#"  className='link-tag' onClick={resendOTP}>Resend OTP</a>
                <Button content="verify" type="submit" arrow="none" disabled={otp.length === 5 ? false : true}/>
              </div>
          </div>
        </form>
        
      </FormLayout>
    </div>
  )
}

export default Verification